import React, { useEffect, useState } from "react";
import _ from "lodash";
import MentorForm from "../components/MentorForm";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";

import feathers from "../etc/feathers-client";

export default function MentorFormPage() {
  const { addToast } = useToasts();
  const [mentor, setMentor] = useState();
  let { mentorId } = useParams();
  useEffect(() => {
    (async function loadMentor() {
      try {
        setMentor(mentorId ? await feathers.service("mentors").get(mentorId) : {});
      } catch (error) {
        addToast(_.get(error, "response.data.message", error.message), {
          appearance: "error",
        });
      }
    })();
  }, [mentorId]);

  if (!mentor) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <MentorForm mentor={mentor} />
    </div>
  );
}
