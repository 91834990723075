import React, { useReducer, createContext, useContext } from "react";

export const CurrentUserContext = createContext();

const initialState = {
  currentUser: {},
  loading: true
};

function reducer(state, action) {
  switch (action.type) {
    case "FETCH_CURRENT_USER": {
      return {
        ...state,
        currentUser: action.payload,
        loading: false
      };
    }
    default:
      throw new Error();
  }
}

export const useCurrentUser = () => {
  const userContext = useContext(CurrentUserContext);
  return {
    currentUser: userContext[0].currentUser,
    loading: userContext[0].loading
  }
};

export const CurrentUserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { children } = props;

  return (
    <CurrentUserContext.Provider value={[state, dispatch]}>
      {children}
    </CurrentUserContext.Provider>
  );
};
