import React, {useState, FormEvent, useEffect, useRef, useCallback} from 'react'
import { Filter as FilterIcon } from 'react-feather'
import {css} from 'glamor'
import { ISubcategoryDisplay } from '../etc/fields'

// Magic number to help with expander animation on subcategories
const ITEM_HEIGHT = 40

interface ICategoryValue {
    id: string,
    label: string
}
interface ICategories {
    [category: string]: ICategoryValue[] // category values to visualize
}

interface IFilterProps {
    categories: ICategories // category values to include.
    visualGroupings: string[][]
    sectionWithSubcategories?: { [category: string]: Array<string | ISubcategoryDisplay> }
    onUpdateFilters?: (filter: ICategories) => void // Strings in category are OR. Between category is AND
    filters: { [category: string]: string[] }
}

interface IFilterButtonProps {
    onClick: () => void
    active: boolean
}

function FilterButton(props: IFilterButtonProps) {
    const [grabAttn, setGrabAttn] = useState(true)
    const timeoutId = useRef(-1)
    // Turn off the background coloring to draw some attention to the filter button
    useEffect(() => {
        if (grabAttn && timeoutId.current) {
            // @ts-ignore
            timeoutId.current = setTimeout(() => {
                setGrabAttn(false)
            }, 1000)
        }
        return () => {
            clearTimeout(timeoutId.current)
        }
    }, [grabAttn, setGrabAttn])
    return (
        <button onClick={props.onClick} {...((props.active || grabAttn)
            ? { ...styles.filterBtn, ...styles.activeFilterBtn } // change the background when filters present
            : styles.filterBtn)
        }>
            <FilterIcon {...styles.filterIcon } color={ (grabAttn || props.active) ? 'white' :  '#323232'}  strokeWidth="1" />
            <span { ...styles.btnLabel }>Filters</span>
        </button>
    )
}

function CategoryExpander (props: ISectionProps & { subcategory: ISubcategoryDisplay}) {
    const { subcategory, values, onFiltersChange, selected, category } = props
    const [open, setOpen] = useState(false)
    return (
        <div>
            <span {...styles.labelSpan} onClick={() => setOpen(!open)}>
                <span {...styles.expandCollapse}>{`${open ? '-' : '+'}`}</span><b>{` ${subcategory.rootLabel}`}</b>
            </span>
            <div {...styles.subcategoryInnerList} style={{ maxHeight: !open ? 0 : ITEM_HEIGHT * subcategory.items.length }}>
            {subcategory.items.length && subcategory.items.map((item: string) => {
                const value = values && values.filter(val => val.id === item)[0];
                return (
                    <div {...styles.label} key={value.id}>
                        <label>
                            <input
                            {...styles.input}
                            name={value.id}
                            type="checkbox"
                            onChange={(event:FormEvent<HTMLInputElement>) => {onFiltersChange(event, category, value.id)}}
                            checked={selected && selected.indexOf(value.id) > -1 ? true : false }
                            value={selected && selected.indexOf(value.id) > -1 ? value.id : ''}
                            />
                            <span {...styles.labelSpan}>{value.label}</span>
                        </label>
                    </div>
                )
            })
            }
            </div>
        </div>
    )
}

interface ISectionProps {
    category:  string,
    categoryDisplay?: Array<string | ISubcategoryDisplay>
    values:  ICategoryValue[]
    selected: string[]
    onFiltersChange: (event:FormEvent<HTMLInputElement>, category: string, value: string) => void
}
function Section (props: ISectionProps) {
    return (
        <>
            <div {...styles.category}>{props.category.replace("_", " ")}</div>
            {/* This section has subcategories which means it needs to render some root nodes as expandable */}
            {props.categoryDisplay && props.categoryDisplay.map((item: string | ISubcategoryDisplay) => {
                if (typeof item === "string") {
                    const value = props.values && props.values.filter(val => val.id === item)[0];

                    return (
                        <div {...styles.label} key={value.id}>
                            <label>
                                <input
                                {...styles.input}
                                name={value.id}
                                type="checkbox"
                                onChange={(event:FormEvent<HTMLInputElement>) => {props.onFiltersChange(event, props.category, value.id)}}
                                checked={props.selected && props.selected.indexOf(value.id) > -1 ? true : false }
                                value={props.selected && props.selected.indexOf(value.id) > -1 ? value.id : ''}
                                />
                                <span {...styles.labelSpan}>{value.label}</span>
                            </label>
                        </div>
                    )
                }
                else if (typeof item === "object") {
                    return (<CategoryExpander key={item.rootLabel} subcategory={item} {...props}/>)
                }
            })
            }
            {/* This is if this section is a flat list*/} 
            {!props.categoryDisplay && props.values.map((value: ICategoryValue) => (
                <div {...styles.label} key={value.id}>
                    <label>
                        <input
                        {...styles.input}
                        name={value.id}
                        type="checkbox"
                        onChange={(event:FormEvent<HTMLInputElement>) => {props.onFiltersChange(event, props.category, value.id)}}
                        checked={props.selected && props.selected.indexOf(value.id) > -1 ? true : false }
                        value={props.selected && props.selected.indexOf(value.id) > -1 ? value.id : ''}
                        />
                        <span {...styles.labelSpan}>{value.label}</span>
                    </label>
                </div>
            ))}
            <br />
        </>
    )
}

interface IColumnProps {
    sectionWithSubcategories: IFilterProps["sectionWithSubcategories"]
    visualGroup: string[],
    categories: any
    filters: { [category: string]: string[] } // filters just keep track of ids
    onFiltersChange: (event:FormEvent<HTMLInputElement>, category: string, value: string) => void
}
function Column (props: IColumnProps) {
    return (
        <div {...styles.column}>
            { props.visualGroup.map(category => (
                <Section
                key={category}
                category={category}
                categoryDisplay={props.sectionWithSubcategories && props.sectionWithSubcategories[category]} // If there's nested display, pass it down
                onFiltersChange={props.onFiltersChange}
                values={props.categories[category]}
                selected={props.filters[category]} />))
            }
        </div>
    )

}

function FilterDrop(props: IFilterProps & {
    display: boolean,
    filters: { [category: string]: string[] } // filters just keep track of ids
    visualGroupings: string[][]
    onClickClose: () => void
    onFiltersChange: (event:FormEvent<HTMLInputElement>, category: string, value: string) => void
}) {
    return (
        <div className="dropdown-content" style={{ display: props.display ? 'block' : undefined }} {...styles.drop}>
            <div {...styles.dropdownInner}>
                {props.visualGroupings.map((visualGroup: string[], idx: number) => {
                    return (<Column
                    key={idx}
                    visualGroup={visualGroup}
                    categories={props.categories}
                    filters={props.filters}
                    sectionWithSubcategories={props.sectionWithSubcategories}
                    onFiltersChange={props.onFiltersChange}
                    />)
                })}
            </div>
            <span role="button" {...styles.close} onClick={props.onClickClose}>close</span>
        </div>
    )
}

function Filter(props: IFilterProps) {
    const { categories, onUpdateFilters, filters } = props;
    const [open, setOpen] = useState(false);
    const onClickClose = useCallback(() => setOpen(false), [setOpen])

    const handleOnChange = (event: FormEvent<HTMLInputElement>, category: string, value: string) => {
        const newFilters = {...filters};
        if (!newFilters.hasOwnProperty(category)) {
            // @ts-ignore
            newFilters[category] = [value];
        } else {
            // @ts-ignore
            const index = newFilters[category].indexOf(value)
            if (index > -1) {
                // @ts-ignore
                newFilters[category].splice(index, 1)
            } else {
                // @ts-ignore
                newFilters[category].push(value)
            }
        }
        // @ts-ignore
        if (onUpdateFilters) onUpdateFilters(newFilters);
    }

    let numActiveFilters = 0;
    Object.keys(filters).forEach(category => {
        if (filters.hasOwnProperty(category)) {
            // @ts-ignore
            numActiveFilters += filters[category].length
        }
    })

    return (
        <span {...styles.filter}>
            <div {...styles.background} onClick={onClickClose} style={{ pointerEvents: open ? 'auto' : 'none' }} />
            <FilterButton onClick={() => setOpen(!open)} active={numActiveFilters > 0}/>
            <FilterDrop {...props}
            display={open}
            categories={categories}
            filters={filters}
            onClickClose={onClickClose}
            onFiltersChange={handleOnChange}/>
        </span>
    )
}

const BREAK_PT = "@media (max-width: 1100px)";

const styles = {
    background: css({
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    }),
    filter: css({
        position: 'relative'
    }),
    filterBtn: css({
        borderRadius: '5px',
        border: '1px solid rgb(170, 170, 170)',
        position: 'relative',
        background: 'white',
        padding: '8px',
        margin: '0 4px',
        height: '55px',
        textAlign: 'center',
        lineHeight: 0.5,
        width: '55px',
        transition: 'background 0.4s ease-out',
        ':hover': {
            background: 'rgba(170, 170, 170, 0.1)'
        }
    }),
    btnLabel: css({
        fontSize: 10
    }),
    activeFilterBtn: css({
        background: '#016766',
        color: 'white',
        ':hover': {
            background: '#67B4B3'
        }
    }),
    filterIcon: css({
        position: 'relative',
        top: '-3px'
    }),
    drop: css({
        border: '1px solid rgba(170, 170, 170, 0.5)',
        top: '40px',
        right: '4px',
        minWidth: 'max-content',
        [BREAK_PT]: {
            position: 'fixed',
            maxWidth: '80vw',
            minWidth: 'initial',
            maxHeight: '85vh',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    }),
    dropdownInner: css({
        display: 'flex',
        [BREAK_PT]: {
            flexDirection: 'column',
        }
    }),
    column: css({
        padding: '16px 24px 16px 16px',
        borderRight: '1px solid rgba(170, 170, 170, 0.5)',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '70vh',
        minWidth: 340,
        [BREAK_PT]: {
            maxHeight: 'initial'
        }
    }),
    category: css({
        fontWeight: 'bold',
        marginTop: '4px',
        textTransform: 'uppercase'
    }),
    subcategoryInnerList: css({
        paddingLeft: '24px',
        overflow: 'hidden',
        transition: 'max-height 500ms ease-in-out'
    }),
    label: css({
        flex: 1,
        margin: '8px 8px 8px 14px'
    }),
    input: css({
        marginRight: '16px'
    }),
    labelSpan: css({
        position: 'relative',
        top: '-2px'
    }),
    expandCollapse: css({
        width: '23px',
        minWidth: '23px',
        textAlign: 'center',
        boxSizing: 'border-box',
        display: 'inline-block',
        backgroundColor: '#C9C8C8',
        borderRadius: '4px',
        padding: '2px 6px',
        cursor: 'pointer',
        marginRight: '8px'
    }),
    close: css({
        color: '#016766',
        padding: '4px',
        position: 'absolute',
        bottom: 1,
        right: 6,
        cursor: 'pointer',
        ':hover': {
            textDecoration: 'underline'
        },
        [BREAK_PT]: {
            position: 'relative',
            textAlign: 'center',
            padding: 20,
            bottom: 0,
            right: 0
        }
    })
}

export default Filter
