import React, { useContext, useState } from "react";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { Redirect, Link } from "react-router-dom";
import _ from "lodash";

import FileUpload from "./FileUpload";
import feathers from "../etc/feathers-client";
import { CurrentUserContext } from "../context/current-user-context";
import FormActionButtons from "./FormActionButtons";
import { checkRoleForService, track } from "../etc/utils";
import FormTextField from "./FormTextField";
import ClinicalSelect from "./ClinicalSelect";
import HoursPerWeekSelect from "./HoursPerWeekSelect";
import CategorySelect from "./CategorySelect";
import IrbCategorySelect from "./IrbCategorySelect";
import AffiliationSelect from "./AffiliationSelect";
import HelpersCountSelect from "./HelpersCountSelect";
import FormStepper from "./FormStepper";
import FormStep from "./FormStep";
import DepartmentDropdownSelect from "./DepartmentDropdownSelect";
import constants from "../constants";
import SkillsSelect from "./SkillsSelect";

import DomainSelect from "./DomainSelect";
import UndergradGradeSelect from "./UndergradGradeSelect";
import UndergradTypeSelect from "./UndergradTypeSelect";

export default function ProjectForm({ project }) {
  const [state] = useContext(CurrentUserContext);
  const [redirect, setRedirect] = useState(false);

  const [savedFiles, setSavedFiles] = useState();
  const [unsavedFiles, setUnsavedFiles] = useState();
  const { addToast } = useToasts();

  // on project creation / trying to keep with the data format of "other" radio select components
  const getCategory = (project) => (project.category && project.category.indexOf("other-") !== -1 ? "other" : project.category);
  const getCategoryOther = (project) => (project.category && project.category.indexOf("other-") !== -1 ? project.category.replace("other-", "") : undefined);
  const getIrbCategories = (project) => {
    if (project.irbCategories && project.irbCategories.length) {
      const irbCategories = [...project.irbCategories];
      const otherDashIdx = irbCategories.findIndex((item) => item.indexOf("other-") !== -1);
      if (otherDashIdx !== -1) {
        irbCategories.splice(otherDashIdx, 1);
        irbCategories.push("other");
      }
      return irbCategories;
    }
  };
  const getIrbCategoriesOther = (project) => {
    if (project.irbCategories && project.irbCategories.length) {
      const others = project.irbCategories.filter((cat) => cat.indexOf("other-") !== -1);
      if (others.length) return others[0].replace("other-", "");
    }
  };
  const parsedProject =
    Object.keys(project).length > 0
      ? {
          ...project,
          // Parsing a Bool to a string here b/c we need the user to make a selection for "isClinical"
          isClinical: project.isClinical ? "true" : "false",
          category: getCategory(project),
          "category-other": getCategoryOther(project),
          irbCategories: getIrbCategories(project),
          "irbCategories-other": getIrbCategoriesOther(project),
        }
      : {};
  const { register, errors, handleSubmit, getValues, watch, triggerValidation } = useForm({
    defaultValues: parsedProject,
  });

  const hasRole = checkRoleForService(state.currentUser, "projects");

  const deleteProject = async (id) => {
    let data = getValues();
    data.isDeleted = true;
    return updateProject(data);
  };

  const createProject = async (data) => {
    const formattedData = Object.assign({}, data);
    formattedData.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    formattedData.unsavedFileTitles = _.map(unsavedFiles, "title");

    // Handle "other fields"
    if (!!data["category-other"]) {
      formattedData.category = `other-${data["category-other"]}`;
      delete formattedData["category-other"];
    }

    if (!!data["irbCategories-other"]) {
      // Swap in the other category
      formattedData.irbCategories = [].concat(data.irbCategories, `other-${data["irbCategories-other"]}`);
      // remove the "other"
      const otherIdx = formattedData.irbCategories.indexOf("other");
      formattedData.irbCategories.splice(otherIdx, 1);
      delete formattedData["irbCategories-other"];
    }

    try {
      await feathers.service("projects").create(formattedData);

      addToast("Project saved", { appearance: "success" });
      track({ category: "projects", action: "create" });
      setRedirect(true);
    } catch (error) {
      console.log(error);
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  //todo: if project is saved by someone else then notify the user using .on('updated)

  async function updateProject(data) {
    const formattedData = Object.assign({}, data);
    formattedData.savedFiles = savedFiles;
    formattedData.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    formattedData.unsavedFileTitles = _.map(unsavedFiles, "title");
    // Handle "other fields"
    if (!!data["category-other"] && data.category === "other") {
      formattedData.category = `other-${data["category-other"]}`;
      delete formattedData["category-other"];
    }

    if (!!data["irbCategories-other"]) {
      // Need to clear this out if the categories field changes
      formattedData.irbCategories = [].concat(data.irbCategories, `other-${data["irbCategories-other"]}`);
      // remove the "other"
      const otherIdx = formattedData.irbCategories.indexOf("other");
      formattedData.irbCategories.splice(otherIdx, 1);
      delete formattedData["irbCategories-other"];
    }

    if (data.category !== "irb_research") {
      formattedData.irbCategories = [];
      delete formattedData["irbCategories-other"];
    }

    try {
      await feathers.service("projects").patch(project._id, formattedData);

      addToast(formattedData.isDeleted ? "Project deleted" : "Project saved", {
        appearance: "success",
      });
      track({
        category: "projects",
        action: "update",
      });
      setRedirect(true);
    } catch (error) {
      console.log(error, data);
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  }

  async function onSubmit(data) {
    if (project._id) {
      await updateProject(data);
    } else {
      await createProject(data);
    }
  }

  React.useEffect(() => {
    setSavedFiles(project.files);
  }, [project]);

  if (redirect) {
    return <Redirect to={project._id ? `/project/${project._id}` : "/projects"} />;
  }
  const department = watch("department");
  const clinical = watch("isClinical");
  const category = watch("category");
  const domain = watch("domain");
  const irbCategories = watch("irbCategories");
  const firstPageFields = ["name", "description", "isUrgent", "status", "domain"];
  const secondPageFields = ["headCount", "hoursPerWeek", "responsibility"];
  const thirdPageFields = ["budget", "department"];
  const fourthPageFields = ["contactName", "contactTitle", "contactAffiliation", "email", "phone", "preferredContact"];
  const isPennMedicine = ["agnew", "radiologyResidents", "pennMedicine"].includes(domain);

  // Conditionally add fields to check if this is an admin
  if (hasRole) {
    firstPageFields.push("authorizedPennIds");
    firstPageFields.push("isVisible");
  }

  // Conditionally adds fields for the stepper to check before progressing
  if (clinical && clinical === "false") {
    thirdPageFields.push("category");
    if (category === "other") {
      thirdPageFields.push("category-other");
    }
    if (category && category === "irb_research") {
      thirdPageFields.push("irbCategories");
      // If they've selected other, make it required
      if (irbCategories && irbCategories.indexOf("other") !== -1) {
        thirdPageFields.push("irbCategories-other");
      }
    }
  }

  if (domain && domain === "curf") {
    secondPageFields.push("undergradTypes");
    secondPageFields.push("undergradGrades");
  }

  if (domain && isPennMedicine) {
    secondPageFields.push("affiliations");
    thirdPageFields.push("isClinical");
  }

  // This logic is for legacy projects that need to be updated.
  // If the project description was longer than the new limit let the user still update the project
  // without having to change the description
  const enforceDescriptionMaxLength = !project._id || project.description.length < constants.project.description.maxLength;

  return (
    <form {...style.form} onSubmit={handleSubmit(onSubmit)}>
      <Link to="/projects" {...style.back}>
        {"<"} Back to all projects
      </Link>
      <h1 {...style.header}>Create a new project</h1>
      <FormStepper
        triggerValidation={triggerValidation}
        showSubmitComponentOnAllPages={typeof project._id !== "undefined"} // let a user submit updates / delete and existing project from any page
        submitComponent={<FormActionButtons isEditing={project._id} deleteFn={() => deleteProject(project._id)} />}
      >
        <FormStep fields={firstPageFields}>
          <Grid stackable columns={2} {...style.mainColumn}>
            <Grid.Column {...style.narrowColumn}>
              <span {...style.sectionHeader}>Tell helpers about your project</span>
              <div {...style.suggestionGroup}>
                <h3 {...style.suggestionHeader}>Keep it short</h3>
                <span {...style.suggestion}>If you have more details to share upload a document below.</span>
              </div>
              <div {...style.suggestionGroup}>
                <h3 {...style.suggestionHeader}>Appeal to people's purpose or desired opportunities for growth.</h3>
                <span {...style.suggestion}>
                  Who does this project help? Why is helping them important? What about the world will change or be improved if your project succeeds?
                </span>
              </div>
              <div {...style.suggestionGroup}>
                <h3 {...style.suggestionHeader}>Highlight skills that helpers may develop or opportunites associated with the project.</h3>
                <span {...style.suggestion}>
                  Does this project involve publishing? Interacting with patients or interesting populations? Exposure to Penn leadership?
                </span>
              </div>
              <div {...style.suggestionGroup}>
                <h3 {...style.suggestionHeader}>Add a keyword list.</h3>
                <span {...style.suggestion}>
                  At the end of the description include a comma-separated list of key words or phrases to ensure your project is in search results.
                </span>
              </div>
            </Grid.Column>
            <Grid.Column {...style.wideColumn}>
              <FormTextField
                name="name"
                label="What is the name of your project?"
                placeholder="Give it a cool name!"
                errors={errors}
                ref={register({ required: true, minLength: 2 })}
              />

              <FormTextField
                type="textarea"
                name="description"
                label="In a few sentences, tell helpers what your project is about. What problem are you aiming to solve and why is it important?"
                placeholder="Keep description brief so that helpers can scan easily for relevance"
                errors={errors}
                maxLengthMessage={`Please limit your text to ${constants.project.description.maxLength} characters`}
                maxLength={enforceDescriptionMaxLength ? constants.project.description.maxLength : undefined} // This forces a hard limit on input, vs. below which warns after the fact
                ref={register({
                  required: true,
                  maxLength: enforceDescriptionMaxLength ? constants.project.description.maxLength : undefined,
                })}
              />

              <DomainSelect label="Who is this project intended for?" value={category} errors={errors} register={register} ref={register({ required: true })} />

              <div {...style.field} className={classnames({ error: errors.isUrgent })}>
                <div {...style.fieldHeader} htmlFor="isUrgent">
                  Does your project need urgent staffing?
                </div>
                <label>
                  <input id="isUrgent" name="isUrgent" type="checkbox" ref={register()} />
                  <span>&nbsp; Yes</span>
                </label>
              </div>

              <div {...style.field} className={classnames({ error: errors.status })}>
                <div {...style.fieldHeader}>Project status</div>
                <label>
                  <input name="status" type="radio" value="has_need" ref={register({ required: true })} />
                  <span {...style.radioChoiceLabel}>Needs help</span>
                </label>
                <label>
                  <input name="status" type="radio" value="no_need" ref={register({ required: true })} />
                  <span {...style.radioChoiceLabel}>No current needs</span>
                </label>
                <label>
                  <input name="status" type="radio" value="closed" ref={register({ required: true })} />
                  <span {...style.radioChoiceLabel}>Project is complete </span>
                </label>
                <br />
                <br />
                {_.get(errors, "status.type") === "required" && <span className="error">Please indicate the status of the project.</span>}
              </div>

              <FormTextField name="endDate" label="Project end date" type="date" errors={errors} ref={register()} />
              {/* <div {...style.field} className={classnames({ error: errors.endDate })}></div> */}
              {hasRole && (
                <>
                  <FormTextField
                    name="authorizedPennIds"
                    label="Penn IDs authorized to edit this project"
                    placeholder="Comma separated list of 8-digit Penn IDs (optional)"
                    errors={errors}
                    ref={register()}
                  />

                  <div {...style.field} className={classnames({ error: errors.isVisible })}>
                    <div {...style.fieldHeader} htmlFor="isVisible">
                      Visible on the site
                    </div>
                    <label>
                      <input id="isVisible" name="isVisible" type="checkbox" ref={register()} />
                      <span className="admin-view">&nbsp; Publish</span>
                    </label>
                  </div>
                </>
              )}

              <SkillsSelect label="What skills are needed?" errors={errors} ref={register()} />
              <FormTextField
                name="skillsComment"
                label="Futher detail about skills"
                placeholder="E.g. MS3, MS4, or type of software engineering"
                errors={errors}
                ref={register()}
              />
              <div {...style.field}>
                <label {...style.fieldHeader} htmlFor="files">
                  Are there any materials or additional information you would like to upload to share with helpers?
                </label>
                <FileUpload unsavedFiles={unsavedFiles} savedFiles={savedFiles} setSavedFiles={setSavedFiles} setUnsavedFiles={setUnsavedFiles} />
              </div>
            </Grid.Column>
          </Grid>
        </FormStep>
        <FormStep fields={secondPageFields}>
          <Grid stackable columns={2} {...style.mainColumn}>
            <Grid.Column {...style.narrowColumn}>
              <span {...style.sectionHeader}>What type of help is your project seeking?</span>
              <div {...style.suggestionGroup}>
                <span {...style.suggestion}>Make it clear to helpers what you'll need</span>
              </div>
              <div {...style.suggestionGroup}>
                <h3 {...style.suggestionHeader}>Make responsibilities clear</h3>
                <span {...style.suggestion}>
                  Frame them using active tense verbs ("helpers will analyze data using Stata" vs "data analysis using Stata needed")
                </span>
              </div>
            </Grid.Column>
            <Grid.Column {...style.wideColumn}>
              <HelpersCountSelect label="How many helpers will you need?" errors={errors} ref={register()} />

              {domain && isPennMedicine && (
                <AffiliationSelect
                  label="What level of affiliation are you looking for?"
                  errors={errors}
                  register={register}
                  ref={register({ required: true })}
                />
              )}

              {domain && domain === "curf" && (
                <UndergradGradeSelect
                  label="Which undergraduate grades will this project be for?"
                  errors={errors}
                  register={register}
                  ref={register({ required: true })}
                />
              )}

              {domain && domain === "curf" && (
                <UndergradTypeSelect
                  label="What type of undergraduate project will this be?"
                  errors={errors}
                  register={register}
                  ref={register({ required: true })}
                />
              )}

              <HoursPerWeekSelect label="How much time will you need helpers to dedicate to the project?" errors={errors} ref={register()} />
              <FormTextField name="responsibility" label="What responsibilities or tasks will the helper have?" errors={errors} ref={register()} />
            </Grid.Column>
          </Grid>
        </FormStep>
        <FormStep fields={thirdPageFields}>
          <Grid stackable columns={2} {...style.mainColumn}>
            <Grid.Column {...style.narrowColumn}>
              <span {...style.sectionHeader}>What category does your project fall in?</span>
              <div {...style.suggestionGroup}>
                <span {...style.suggestion}>Categorize your project to make it simple for helpers to find what they're looking for.</span>
              </div>
            </Grid.Column>
            <Grid.Column {...style.wideColumn}>
              {domain && isPennMedicine && (
                <ClinicalSelect
                  label="Does your project require helpers to provide clinical care to patients?"
                  errors={errors}
                  ref={register({ required: true })}
                />
              )}

              {clinical && clinical === "false" && (
                <CategorySelect
                  label="What is the PRIMARY way you describe this project opportunity (we realize projects can fit more than one category, but please pick one)"
                  value={category}
                  errors={errors}
                  register={register}
                  ref={register({ required: true })}
                />
              )}
              {category && category === "irb_research" && (
                <IrbCategorySelect
                  label="What type of research is it? Choose any that apply."
                  value={irbCategories}
                  register={register}
                  errors={errors}
                  ref={register({ required: true })}
                />
              )}
              <FormTextField
                name="budget"
                label="Is there funding for this project? If so, how much? (dollars/hr or dollars/month). Is this negotiable?"
                errors={errors}
                ref={register()}
              />
              <DepartmentDropdownSelect
                placeholder="Select a field"
                errors={errors}
                ref={register()}
                // ref={register({ required: true })}
                value={department}
                label='What field is project in (for example, "cardiology" or "neuroscience”)?'
              />
            </Grid.Column>
          </Grid>
        </FormStep>
        <FormStep fields={fourthPageFields}>
          <Grid stackable columns={2} {...style.mainColumn}>
            <Grid.Column {...style.narrowColumn}>
              <span {...style.sectionHeader}>Who is leading the project?</span>
            </Grid.Column>
            <Grid.Column {...style.wideColumn}>
              <FormTextField name="contactName" label="Name" errors={errors} ref={register()} />
              <FormTextField name="contactTitle" label="Title" errors={errors} ref={register()} />
              <FormTextField name="contactAffiliation" label="Affiliation" errors={errors} ref={register()} />
              <FormTextField
                name="email"
                type="email"
                label="Email"
                errors={errors}
                ref={register({
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                })}
                requiredMessage="Please provide a contact address for this posting."
                patternMessage="Invalid email address"
              />

              <FormTextField name="phone" label="Phone" errors={errors} ref={register()} />

              <FormTextField
                name="preferredContact"
                label="Preferred mode of communication"
                placeholder="E.g. call, text, email"
                errors={errors}
                ref={register()}
              />
            </Grid.Column>
          </Grid>
        </FormStep>
      </FormStepper>
    </form>
  );
}

const style = {
  form: css({
    paddingTop: 60,
    paddingBottom: 100,
  }),
  back: css({
    color: "#969696",
  }),
  header: css({
    marginTop: 0,
    marginBottom: 56,
    fontSize: 36,
    color: "#323232",
  }),
  radioChoiceLabel: css({
    margin: "0 10px",
    fontWeight: "normal",
  }),
  field: css({ margin: "20px 0 50px 0" }),
  fieldHeader: css({
    fontSize: "20px",
    color: "#323232",
    fontWeight: "bold",
    display: "block",
    marginBottom: 20,
  }),
  sectionHeader: css({
    fontWeight: "bold",
    fontSize: 20,
    color: "#016766",
    marginBottom: 20,
  }),
  activeSectionNumber: css({
    color: "#016766",
  }),
  sectionNumber: css({
    color: "#C9C8C8",
    marginRight: "16px",
  }),
  sectionDescription: css({
    fontWeight: "bold",
  }),
  mainColumn: css({
    flex: 1,
  }),
  narrowColumn: css({
    flexBasis: 380,
    minWidth: 300,
    marginRight: 100,
    flexDirection: "column",
    display: "flex !important",
  }),
  wideColumn: css({
    flexBasis: 650,
    minWidth: 380,
    flex: 1,
  }),
  suggestionGroup: css({
    marginBottom: 30,
  }),
  suggestionHeader: css({
    fontWeight: "bold",
    color: "#969696",
    marginBottom: 10,
  }),
  suggestion: css({
    color: "#969696",
    lineHeight: "25px",
    fontStyle: "italic",
  }),
};
