import React, { useEffect, useState } from "react";
import feathers from "../etc/feathers-client";
import Button from "./Button";
import Loading from "./Loading";
import SitePermissionsSelect from "./SitePermissionsSelect";
import ProjectPermissionsSelect from "./ProjectPermissionsSelect";
import { Dropdown, Grid } from "semantic-ui-react";
import { css } from "glamor";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";

import { useForm } from "react-hook-form";
import { useCurrentUser } from "../context/current-user-context";
import { Redirect } from "react-router-dom";

const queryUsers = () => {
  return feathers.service("users").find({
    query: {
      $limit: -1,
    },
  });
};

const queryUser = (userId) => {
  return feathers.service("users").get(userId);
  // query: {
  //   _id: userId,
  // },
  // });
};

const toTitleCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export default function AdminPermissions() {
  // Get the user info
  const { currentUser } = useCurrentUser();
  const permissions = currentUser.permissions && currentUser.permissions.length ? currentUser.permissions : [];
  const isAdmin = permissions.includes("admin");

  // State vals
  const { addToast } = useToasts();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  async function requestPage() {
    try {
      setLoading(true);
      const usersQuery = await queryUsers();
      const sortedUsers = usersQuery.sort(function (a, b) {
        var x = a.name ? a.name.toLowerCase() : a.pennName.toLowerCase();
        var y = b.name ? b.name.toLowerCase() : b.pennName.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setUsers(sortedUsers);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    requestPage();
  }, []);

  const updateUserPermissions = async (data) => {
    try {
      const ret = await feathers.service("users").patch(user._id, { permissions: data.permissions });
      addToast("Updated", {
        appearance: "success",
      });
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  const { register, errors, handleSubmit, getValues, watch, triggerValidation, setValue } = useForm({});

  const onSubmit = async (data) => {
    if (!user._id) {
      addToast("Please select a user", {
        appearance: "error",
      });
    } else {
      updateUserPermissions(data);
    }
  };

  const handleChange = async (e, { value }) => {
    const ret = await queryUser(value);
    setUser(ret);
    setValue("permissions", ret.permissions);
  };

  if (loading) {
    return <Loading />;
  }
  if (!isAdmin || !users) {
    return <Redirect to="/" />;
  }

  // Set the user mapping
  const usersOptions = users.map((u) => {
    return {
      key: u._id,
      text: u.name ? toTitleCase(u.name + " (" + u.pennName + ")") : u.pennName,
      value: u._id,
    };
  });

  return (
    <div style={{ marginTop: 20 }}>
      <h1>Edit user permissions</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid style={{ marginTop: 20 }}>
          <Grid.Row columns={2}>
            <Grid.Column floated="left">
              <Dropdown
                placeholder="Select user..."
                fluid
                search
                selection
                onChange={handleChange}
                options={usersOptions}
                style={{ marginTop: 20 }}
                value={user._id}
              />
            </Grid.Column>
            <Grid.Column floated="right">
              <SitePermissionsSelect label="Global Permissions" errors={errors} ref={register()} />
              <ProjectPermissionsSelect label="Project Permissions" errors={errors} ref={register()} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button type="submit">Save</Button>
          </Grid.Row>
        </Grid>
      </form>
    </div>
  );
}

const style = {
  form: css({
    paddingTop: 60,
    paddingBottom: 100,
  }),
  modal: css({
    maxWidth: "700px",
  }),
};
