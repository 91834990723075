import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { css } from 'glamor'
import { useToasts } from 'react-toast-notifications'
import feathers from '../etc/feathers-client'
import _ from 'lodash'

import Button from './Button'
import FormTextField from './FormTextField'
import { track } from '../etc/utils'
import { useCurrentUser } from '../context/current-user-context'
import Loading from './Loading'
import Sending from './Sending'

function ApplyToProjectForm ({ project, requestClose }) {
    const { currentUser } = useCurrentUser()
    const [fetchingResource, setFetchingResource] = useState(true)
    const [sendingApplication, setSendingApplication] = useState(false)
    const [sent, setSent] = useState(false)
    const [resource, setResource] = useState(null)
    const {
        register,
        errors,
        handleSubmit,
    } = useForm({})
    const { addToast } = useToasts()

    // See if they have a helper account
    useEffect(() => {
        async function getHelper() {
            const resource = await feathers.service("resources").find({
                query: {
                    $limit: 1,
                    userId: currentUser._id
                }
            })
            if (resource.data.length) {
                setResource(resource.data[0])
            }
            setFetchingResource(false)
        }
        getHelper()
    }, [currentUser._id]) // let's do this on mount each time. It'll make sure we fetch again if they have to go and create a helper account

    const submitApplication = async ({ message }) => {
        const applicationData = {
            projectId: project._id,
            message,
        }
        try {
            setSendingApplication(true)
            await feathers.service("applications").create(applicationData)
            track({ category: "applications", action: "create" })
            setSent(true)
            setSendingApplication(false)
        } catch (error) {
            console.log(error)
            addToast(_.get(error, "response.data.message", error.message), {
              appearance: "error",
            })
        }
    }

    async function onSubmit(data) {
        await submitApplication(data)
    }

    if (sent) return (
        <div {...styles.content}>
            <span>{`Your message and helper profile have been shared with ${project.contactName}.
            If you’re a good match, ${project.contactName} will reach out to you directly.`}</span>
            <div {...styles.spacer} />
            <Button onClick={requestClose}>Close</Button>
        </div>
    )

    if (fetchingResource) return <Loading />
    if (sendingApplication) return <Sending />

    if (!fetchingResource && resource === null) {
        return (
            <div {...styles.content}>
                <span>Please create a helper account before applying to a project.</span>
                <div {...styles.spacer} />
                <Link to="/resources/new"><Button>Create helper account</Button></Link>
            </div>
        )
    }

    return (
        <div>
            <div {...styles.label}>Project Lead</div>
            {`${project.contactTitle && project.contactTitle} ${project.contactName && project.contactName}`}<br /><br />

            <div {...styles.label}>Instructions</div>
            Make a quick introduction to the project lead.<br /><br />
            In a few sentences, share who you are and why you’re interested. For example, what program/year are you? What about the project excites you or aligns with your skills?

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormTextField
                type="textarea"
                name="message"
                placeholder=""
                errors={errors}
                ref={register({ required: true })}
                />
                <Button type="submit">Send</Button>
            </form>
        </div>
    )
}

const styles = {
    content: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        minHeight: 200,
        textAlign: 'center'
    }),
    label: css({
        color: "#229292",
        textTransform: "uppercase",
        display: "block",
        marginBottom: 5,
        fontSize: "0.9rem",
    }),
    spacer: css({
        height: 40
    }),
}

export default ApplyToProjectForm
