// https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook/54666498
import { useState, useEffect } from 'react';

export default (value: any, timeout: number) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);

        return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
}