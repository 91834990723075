import React from 'react'
import {css} from 'glamor'

function Sending() {
    return (
        <div {...styles.sending}>Sending...</div>
    )
}

const styles = {
    sending: css({
        margin: '20px auto',
        fontSize: '1.2em'
    })
}

export default Sending
