import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import ReactGA from "react-ga";

import "fomantic-ui-css/semantic.min.css"; // todo: remove this once dependence on Semantic-ui is removed
import "toasted-notes/src/styles.css"; // optional styles
import "./style/index.css";
import "./style/dropdown.css";
import App from "./App";
import { CurrentUserContextProvider } from "./context/current-user-context";

// import Typography from "typography";

ReactGA.initialize("UA-162751290-1"); //"G-9E70KRX21W");

// const typography = new Typography({
//   baseFontSize: "18px",
//   baseLineHeight: 1.666,
//   headerFontFamily: [
//     "Avenir Next",
//     "Helvetica Neue",
//     "Segoe UI",
//     "Helvetica",
//     "Arial",
//     "sans-serif",
//   ],
//   bodyFontFamily: ["Georgia", "serif"],
// });

// // typography.injectStyles();

ReactDOM.render(
  <CurrentUserContextProvider>
    <BrowserRouter>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}
        // components={{ Toast: Snack }}
        placement="top-center"
      >
        <App />
      </ToastProvider>
    </BrowserRouter>
  </CurrentUserContextProvider>,
  document.getElementById("root")
);
