import React from "react";
import { css } from "glamor";
import classnames from "classnames";
import _ from "lodash";

import TextInput from "./TextInput";
import TextArea from "./TextArea";

const Field = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    label,
    placeholder,
    errors,
    requiredMessage,
    patternMessage,
    minLengthMessage,
    maxLengthMessage
  } = props;
  return (
    <div {...style.field} className={classnames({ error: errors[name] })}>
      <label {...style.fieldHeader} htmlFor={name}>
        {label}
      </label>
      {type === "textarea" ? (
        <TextArea
          id={name}
          name={name}
          errors={errors}
          maxLength={props.maxLength}
          placeholder={placeholder || ""}
          ref={ref}
        />
      ) : (
        <TextInput
          id={name}
          type={type || "text"}
          name={name}
          errors={errors}
          maxLength={props.maxLength}
          placeholder={placeholder || ""}
          ref={ref}
        />
      )}
      {_.get(errors, [name, "type"]) === "required" && (
        <span className="error" role="alert">
          {requiredMessage || "Please provide an answer"}
        </span>
      )}
      {_.get(errors, [name, "type"]) === "pattern" && (
        <span className="error" role="alert">
          {patternMessage || "Invalid format response"}
        </span>
      )}
      {_.get(errors, [name, "type"]) === "minLength" && (
        <span className="error" role="alert">
          {minLengthMessage || "Please enter more text"}
        </span>
      )}
      {_.get(errors, [name, "type"]) === "maxLength" && (
        <span className="error">
          {maxLengthMessage || "Please limit your text"}
        </span>
      )}
    </div>
  );
});

export default Field;
const style = {
  field: css({ margin: "0 0 30px 0" }),
  fieldHeader: css({
    fontSize: '20px', 
    color: '#323232',
    fontWeight: 'bold',
    display: "block",
    marginBottom: 5,
  }),
};
