import React from "react";
import { css } from "glamor";
import classnames from "classnames";
import _ from "lodash";
import TextInput from './TextInput'

const Field = React.forwardRef((props, ref) => {
  const { name, label, errors, options, type, allowUserInput = false, value, register } = props;
  return (
    <div {...css(style.field, props.style) } className={classnames({ error: errors[name] })}>
      <label {...style.fieldHeader} htmlFor={name}>
        {label}
      </label>

      {options.map((option) => (
        <React.Fragment key={_.get(option, "id", option)}>
          <label>
            <input
              aria-invalid={_.get(errors, [name, "type"]) === "required"}
              aria-describedby="error"
              name={name}
              type={type || "radio"}
              value={_.get(option, "id", option)}
              ref={ref}
            />
            <span {...style.radioChoiceLabel}>
              {_.get(option, "label", option)}
            </span>
          </label>
          <br />
        </React.Fragment>
      ))}
      {(allowUserInput && value  && (value === "other" || (Array.isArray(value) && value.indexOf('other') !== -1))) && (
        <div {...style.other}>
          <TextInput
          type="text"
          name={`${name}-other`}
          id="user-input"
          placeholder="Other value"
          ref={register({ pattern: /^[A-Za-z0-9_.-\s]+$/gi, required: true })}
          />
        </div>
      )}
      {_.get(errors, [name, "type"]) === "required" && (
        <span className="error">Please specify</span>
      )}
      {_.get(errors, [`${name}-other`, "type"]) && (
        <span className="error">Please only use letters and numbers for other categories.</span>
      )}
    </div>
  );
});

export default Field;
const style = {
  radioChoiceLabel: css({
    margin: "0 10px",
    fontWeight: "normal",
  }),
  other: css({
    marginLeft: 24
  }),
  field: css({ margin: "20px 0 50px 0" }),
  fieldHeader: css({
    fontSize: '20px', 
    color: '#323232',
    fontWeight: 'bold',
    display: "block",
    marginBottom: 20
  })
};
