import React from "react";
import { css } from "glamor";
import ResourceCard from "./ResourceCard";

export default function ResourceList({ resources, bookmarks, toggleBookmark }) {
  return (
    <div {...style.grid}>
      {resources.map(resource => {
        const bookmark = bookmarks.filter(b => b.itemId === resource._id);
        const bookmarked = bookmark.length > 0 && !bookmark[0].isDeleted;
        return (
          <div {...style.card} key={resource._id}>
            <ResourceCard resource={resource} toggleBookmark={toggleBookmark} bookmarked={bookmarked} />
          </div>
        )
      })}
    </div>
  );
}

const style = {
  grid: css({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  }),
  card: css({
    margin: "20px 0",
    flexBasis: "48%",
    textLeft: "left",
    transition: "color 0.15s ease, border-color 0.15s ease",
    "@media (max-width: 480px)": {
      flexBasis: "100%"
    }
  })
};
