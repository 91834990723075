import React from "react";
import { css } from "glamor";
import moment from "moment";

import { CurrentUserContext } from '../context/current-user-context';
import { checkRoleForService } from "../etc/utils";



const { useContext } = React;

export default function CommentCard({ comment, deleteComment }) {
  // eslint-disable-next-line no-unused-vars
  const curUserContext = useContext(CurrentUserContext);
  const curUserState = curUserContext[0];

  const isOwnedByUser = curUserState.currentUser._id === comment.userId;
  const canEdit =
    isOwnedByUser || checkRoleForService(curUserState.currentUser, "projects")

  return (
    <div {...style.comment}>
      <div {...style.info}>
        {comment.user.name}
        <span> &middot; </span>
        {moment(comment.createdAt).format("M/D/YYYY h:mma")}
      </div>
      <div {...style.text}>{comment.text}</div>
      {canEdit && <div {...style.delete} onClick={() => deleteComment(comment)}>delete</div>}

      {/* <Link to={`/comments/edit/${comment._id}`}>edit</Link> */}
    </div>
  );
}

const style = {
  comment: css({
    margin: "10px 0",
    border: '1px solid #C9C8C8',
    padding: '16px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,0.03)',
    position: 'relative'
  }),
  info: css({ color: "#C9C8C8", textTransform: "uppercase", size: 12 }),
  text: css({ fontSize: 16, color: "#323232" }),
  delete: css({
    fontSize: '1rem',
    position: 'absolute',
    top: '7px',
    right: '9px',
    color: '#323232',
    ':hover': {
      color: 'black',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  })
};
