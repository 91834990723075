import React from "react";
import { css } from "glamor";
import _ from 'lodash';

const TextArea = React.forwardRef((props, ref) => {
  return <textarea 
    {...style.input} 
    ref={ref} 
    maxLength={props.maxLength ? props.maxLength : undefined}
    aria-invalid={_.get(props.errors, [props.name, "type"]) === "maxLength"}
    {...props} />;
});

export default TextArea;
const style = {
  input: css({
    lineHeight: "1.2rem",
    fontSize: "1em",
    padding: ".6em 0",
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: "1px solid rgba(34,36,38,.15)",
    color: "rgba(0,0,0,.87)",
    borderRadius: 1,
    boxShadow: "0 0 0 0 transparent inset",
    transition: "color .1s ease,border-color .1s ease",
    width: "100%",
    verticalAlign: "top",
    WebkitAppearance: "none",
    WebkitTapHighlightColor: "rgba(255,255,255,0)",
    marginBottom: 30,
    resize: "vertical",
    maxHeight: "24em",
    ':focus': {
      padding: ".6em"
    }
  }),
};
