import React from "react";

import { hoursPerWeek } from "../etc/fields";
import RadioGroup from "./RadioGroup";

const Field = React.forwardRef((props, ref) => {
  const { label, errors } = props;
  return (
    <RadioGroup
      name="hoursPerWeek"
      options={hoursPerWeek}
      label={label}
      errors={errors}
      ref={ref}
    />
  );
});

export default Field;
