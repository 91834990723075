import React, { useEffect, useState } from "react";
import { css } from "glamor";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import feathers from "../etc/feathers-client";
import ProjectList from "../components/ProjectList";
import TextFilter from "../components/TextFilter";
import CategoryFilter from "../components/CategoryFilter.tsx";
import BookmarkFilter from "../components/BookmarkFilter.tsx";
import ShowMore from "..//components/ShowMore.tsx";
import Button from "../components/Button";
import useApplications from "../hooks/Applications";
import { useCurrentUser } from "../context/current-user-context";
import { useBookmarks } from "../hooks/Bookmarks";
import useDebounce from "../hooks/Debounce";
import { checkRoleForService } from "../etc/utils";
// Build the categories for the filter widget
import { categories, projectStatusLabel, departments, departmentsDisplay, irbCategories, domains } from "../etc/fields";
import FilterCount from "../components/FilterCount";
const projectCateogries = categories;
const projectStatus = Object.keys(projectStatusLabel).map((id) => ({
  id,
  label: projectStatusLabel[id],
}));

const projectStatuses = ["has_need", "no_need", "closed"];

const PAGE_LIMIT = 30;

const formatFiltersForBackend = ({ isVisible, categoryFilters, bookmarks, filterText, filterBookmarks }) => {
  // Published status
  let filters = { isVisible };

  // Filter for domain
  if (categoryFilters.domain && categoryFilters.domain.length) {
    filters.domain = {
      $in: categoryFilters.domain,
    };
  }

  // Filter for category
  if (categoryFilters.category && categoryFilters.category.length) {
    if (typeof filters.orRegex === "undefined") filters.orRegex = [];
    filters.orRegex = filters.orRegex.concat(categoryFilters.category.map((filter) => ({ category: { $search: filter } })));
  }

  // filter for status
  if (categoryFilters.status && categoryFilters.status.length) {
    filters.status = {
      $in: categoryFilters.status,
    };
  }

  // filter for department
  if (categoryFilters.department && categoryFilters.department.length) {
    filters.department = {
      $in: categoryFilters.department,
    };
  }

  // filter for irbCategory
  if (categoryFilters.irb_Category && categoryFilters.irb_Category.length) {
    if (typeof filters.orRegex === "undefined") filters.orRegex = [];
    filters.orRegex = filters.orRegex.concat(categoryFilters.irb_Category.map((filter) => ({ irbCategories: { $search: filter } })));
  }

  // urgency
  if (categoryFilters.urgency && categoryFilters.urgency.length) {
    filters.isUrgent = true;
  }

  // Bookmarks
  if (filterBookmarks) {
    const bookmarkIds = bookmarks.filter((b) => !b.isDeleted).map((b) => b.itemId);
    filters._id = {
      $in: bookmarkIds,
    };
  }

  // Search terms
  if (filterText && filterText.length) {
    if (typeof filters.orRegex === "undefined") filters.orRegex = [];
    filters.orRegex = filters.orRegex.concat([
      {
        name: {
          $search: filterText,
        },
      },
      {
        description: {
          $search: filterText,
        },
      },
      {
        responsibility: {
          $search: filterText,
        },
      },
      {
        skillsComment: {
          $search: filterText,
        },
      },
      {
        contactName: {
          $search: filterText,
        },
      },
      {
        contactAffiliation: {
          $search: filterText,
        },
      },
      {
        email: {
          $search: filterText,
        },
      },
      {
        department: {
          $search: filterText,
        },
      },
      {
        // ToDo: see if this queries the array
        irbCategories: {
          $search: filterText,
        },
      },
      {
        cateogry: {
          $search: filterText,
        },
      },
    ]);
  }

  return filters;
};

export default function ProjectListPage() {
  const { currentUser } = useCurrentUser();
  const { applications } = useApplications({ userId: currentUser._id });
  const [totalProjects, setTotalProjects] = useState(0);
  const [queryTotalProjects, setQueryTotalProjects] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [categoryFilters, setCategoryFilters] = useState({});
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const { bookmarks, toggleBookmark } = useBookmarks({ type: "projects" });
  const [filterBookmarks, setFilterBookmarks] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const clearFilters = () => {
    setCategoryFilters({});
    setFilterText("");
    setFilterBookmarks(false);
    setIsVisible(true);
  };

  const debouncedFilterText = useDebounce(filterText, 200);
  const hasRole = checkRoleForService(currentUser, "projects");
  const loadProjects = React.useCallback(
    async function () {
      try {
        const backendFilters = formatFiltersForBackend({ categoryFilters, filterText: debouncedFilterText, filterBookmarks, bookmarks, isVisible });

        const projectPage = await feathers.service("projects").find({
          query: {
            ...backendFilters,
            isDeleted: null,
            $sort: {
              isUrgent: -1,
              createdAt: -1,
            },
            $limit: PAGE_LIMIT,
          },
        });
        setQueryTotalProjects(projectPage.total);
        setProjects(projectPage.data);
      } catch (e) {
        console.log(e);
      }
    },
    [isVisible, categoryFilters, debouncedFilterText, filterBookmarks, bookmarks]
  );

  async function requestNextPage() {
    try {
      const backendFilters = formatFiltersForBackend({ categoryFilters, filterText, filterBookmarks, bookmarks, isVisible });
      const projectPage = await feathers.service("projects").find({
        query: {
          ...backendFilters,
          isDeleted: null,
          $skip: projects.length,
          $sort: {
            isUrgent: -1,
            createdAt: -1,
          },
          $limit: PAGE_LIMIT,
        },
      });
      setQueryTotalProjects(projectPage.total);
      setProjects([...projects, ...projectPage.data]);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    loadProjects();
  }, [loadProjects, isVisible, categoryFilters, debouncedFilterText, filterBookmarks, bookmarks]);

  // get project count once on load
  useEffect(() => {
    // onload, get total number of projects
    (async function getProjectCount() {
      try {
        const projectPage = await feathers.service("projects").find({
          query: {
            isVisible,
            isDeleted: null,
            $limit: 0,
          },
        });

        setTotalProjects(projectPage.total);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [isVisible]);

  const applicationProjectIds = applications.map((application) => application.projectId);
  const projectsWithUserData = projects.map((project) => {
    const bookmarkIdx = bookmarks.map((b) => b.itemId).indexOf(project._id);
    const bookmarked = bookmarkIdx !== -1 && !bookmarks[bookmarkIdx].isDeleted;
    return {
      ...project,
      _currentUserApplied: applicationProjectIds.indexOf(project._id) !== -1,
      bookmarked,
    };
  });

  return (
    <div>
      {hasRole && (
        <div {...style.adminRow} className="admin-view">
          <span {...(isVisible ? style.activeTextButton : style.inactiveTextButton)} onClick={() => setIsVisible(true)}>
            Published
          </span>
          <span {...(!isVisible ? style.activeTextButton : style.inactiveTextButton)} onClick={() => setIsVisible(false)}>
            Unpublished
          </span>
        </div>
      )}

      <div {...style.actionRow}>
        <Button handleClick={() => history.push("/projects/new")}>Create a new project</Button>
        <div {...style.filterContainer}>
          <BookmarkFilter active={filterBookmarks} toggleBookmarks={() => setFilterBookmarks(!filterBookmarks)} />
          <TextFilter filterText={filterText} setFilterText={setFilterText} label={"Type a keyword to filter..."} />
          <CategoryFilter
            categories={{
              domain: domains,
              category: projectCateogries,
              department: departments,
              irb_Category: irbCategories, // Property name is with _ for display purposes-- .replace("_", " ")
              status: projectStatus,
              urgency: [{ id: "isUrgent", label: "Urgent" }],
            }}
            sectionWithSubcategories={{
              // These dictate if a section has a collapsable sub category
              department: departmentsDisplay,
            }}
            visualGroupings={[["domain", "category", "irb_Category"], ["department"], ["status", "urgency"]]} // These dictate what go into each column
            filters={categoryFilters}
            onUpdateFilters={(filters) => setCategoryFilters(filters)}
          />
        </div>
      </div>

      {isVisible && <FilterCount alwaysShow active={queryTotalProjects} total={totalProjects} itemLabel="projects" clearFiltersFn={clearFilters} />}

      <ProjectList
        clickBookmarkProject={toggleBookmark}
        bookmarks={bookmarks}
        projects={_.sortBy(projectsWithUserData, [(p) => projectStatuses.indexOf(p.status), (p) => !p._currentUserApplied])}
      />
      {projectsWithUserData.length < queryTotalProjects && <ShowMore onClick={() => requestNextPage()} />}
    </div>
  );
}

const style = {
  filterContainer: css({
    display: "flex",
    maxWidth: "100%",
    "@media (max-width: 500px)": { marginTop: 10 },
  }),
  actionRow: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0",
    flexWrap: "wrap",
  }),
  adminRow: css({
    textAlign: "right",
  }),
  activeTextButton: css({
    fontWeight: "bold",
    margin: "0 10px ",
  }),
  inactiveTextButton: css({
    margin: "0 10px",
    cursor: "pointer",
    textDecoration: "underline",
  }),
};
