import React from 'react'
import {css} from 'glamor'

export const Table = props => (
    <table {...props} {...styles.table}>{props.children}</table>
)

export const Thead = props => (
    <thead {...styles.thead}>{props.children}</thead>
)
export const Tbody = props => (
    <tbody {...styles.tbody}>{props.children}</tbody>
)

export const Tr = props => (
    <tr {...styles.tr}>{props.children}</tr>
)

export const Th = props => (
    <th {...styles.th}>{props.children}</th>
)

export const Td = props => (
    <td {...styles.td}>{props.children}</td>
)

const styles = {
    table: css({
        borderCollapse: 'collapse',
        border: '1px solid #969696',
    }),
    thead: css({
        backgroundColor: '#016766',
        color: 'white'
    }),
    th: css({
        padding: '10px',
        border: '1px solid #969696',
        textAlign: 'left'
    }),
    td: css({
        padding: '10px',
        border: '1px solid #969696',
    })
}
