import React, { useState, useEffect, useRef } from 'react'
import { css } from 'glamor'
import Button from './Button'

interface IFormStepperProps {
    children: React.ReactChild[]
    submitComponent: React.ReactElement
    triggerValidation: any
    showSubmitComponentOnAllPages?: boolean
}

function StepIndicator({ currentStep, totalSteps} : { currentStep: number, totalSteps: number }){
    let steps = []
    for (let i = 1; i <= totalSteps; i++) {
        steps.push(
            <div key={i} { ...css(styles.indicator, (i <= currentStep && styles.activeIndicator))} />
        )
    }
    return (
        <div {...styles.indicatorGroup}>
            {steps}
        </div>
    )
}

function getControls(
    currentStep: number, 
    totalSteps: number,
    submitComponent: React.ReactElement,
    setCurrentStep: (step: number) => void,
    triggerValidation: any,
    currentStepFields: string[],
    showSubmitComponentOnAllPages?: boolean
) {
    let buttons
    const showSubmit = showSubmitComponentOnAllPages || currentStep > 1 && currentStep === totalSteps
    const checkValidationAndProgress = async () => {
        // ToDo: get the validations for just this page
        // Pass list of fields down
        Promise.all(currentStepFields.map(async (field: string) => await triggerValidation(field)))
            .then(values => {
                const errors = values.filter(val => !val)
                // no errors, step to next page
                if (errors.length === 0) {
                    setCurrentStep(currentStep + 1)
                }
            })
    }
    if (currentStep === 1) { // buttons for first step
        buttons = (
            <Button onClick={ () => checkValidationAndProgress()}>Next</Button>
        )
    } else if (currentStep > 1 && currentStep !== totalSteps) { // buttons for intermediate step
        buttons = (
            <>
                <Button secondary onClick={ () => setCurrentStep(currentStep - 1) }>Back</Button>
                <div {...styles.spacer} />
                <Button onClick={ () => checkValidationAndProgress()}>Next</Button>
            </>
        )

    }
    else if (currentStep > 1 && currentStep === totalSteps) { // buttons for final step
        buttons = (
            <>
                <Button secondary onClick={ () => setCurrentStep(currentStep - 1) }>Back</Button>
            </>
        )
    }
    return (
        <div {...styles.flexEnd}>
            {buttons}
            {showSubmit && (
                <>
                    <div {...styles.spacer} />
                    {submitComponent}
                </>
            )}
        </div>
    )
}


function FormStepper(props: IFormStepperProps) {
    const [currentStep, setCurrentStep] = useState(1)
    const totalSteps = props.children.length
    const htmlRef = useRef(null)

    useEffect(() => {
        if (!htmlRef.current) {
            // @ts-ignore
            htmlRef.current = document.querySelector("html")
        }
        // @ts-ignore
        htmlRef.current.scrollTop = 0
    }, [currentStep])

    let currentStepFields = []
    // @ts-ignore
    if (props.children[currentStep-1] && props.children[currentStep-1].props.fields) {
        // @ts-ignore
        currentStepFields = props.children[currentStep-1].props.fields
    }

    return (
        <div className="form-stepper" {...css(styles.flex, { flexDirection: 'column'})}>
            <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
            {React.Children.map(props.children, (child, idx) => {
                return React.cloneElement(
                    child as React.ReactElement<any>,
                    {
                        style: {
                            display: (idx === currentStep - 1 ? 'block' : 'none')
                        }
                    }
                )
            })}
            { getControls(
                currentStep, 
                totalSteps, 
                props.submitComponent, 
                setCurrentStep, 
                props.triggerValidation, 
                currentStepFields, 
                props.showSubmitComponentOnAllPages
            )}
        </div>
    )
}


const styles = {
    flex: css({
        display: 'flex'
    }),
    flexEnd: css({
        display: 'flex',
        justifyContent: 'flex-end'
    }),
    spacer: css({
        width: 30
    }),
    indicatorGroup: css({
        width: 380,
        display: 'flex',
        height: 10,
        margin: '24px 0'
    }),
    indicator: css({
        flex: 1,
        borderRadius: 5,
        backgroundColor: '#CAC9C9',
        marginRight: 8
    }),
    activeIndicator: css({
        backgroundColor: '#2A6665'
    }),
    controls: css({
        justifyContent: 'end'
    }),
}

export default FormStepper