import {useEffect, useState} from 'react'
import feathers from '../etc/feathers-client'
import { useCurrentUser } from '../context/current-user-context'

/**
 * This will fetch the current user's applications unless a userId arg is sent
 */
interface IApplicationsArgs {
    userId?: string,
    toggle?: boolean
}
export function useApplications({ userId, toggle } : IApplicationsArgs) {
    const [applications, setApplications] = useState([])
    const [loading, setLoading] = useState(true)
    const { currentUser } = useCurrentUser()
    const currentUserId = userId || currentUser._id

    useEffect(() => {
        async function fetchApplications(currentUserId: string) {
            try {
                const applications = await feathers.service("applications").find({
                    query: {
                        userId: currentUserId
                    }
                })
                if (applications.data.length) {
                    setApplications(applications.data)
                }
                setLoading(false)
            } catch (error)  {
                console.log(error)
            }
        }
        // fetch
        if (typeof currentUserId !== 'undefined') fetchApplications(currentUserId)
    }, [currentUserId, toggle])

    return { loading, applications }
}

export default useApplications
