export const categories = [
  { id: "medical_education", label: "Medical education" },
  { id: "qi", label: "Quality improvement" },
  { id: "community_volunteering", label: "Community volunteering" },
  { id: "technology", label: "Technology" },
  { id: "irb_research", label: "IRB-approved research" },
  { id: "other", label: "Other" },
];

export const irbCategories = [
  { id: "clinical", label: "Clinical" },
  { id: "quantitative", label: "Quantitative" },
  { id: "Qualitative", label: "Qualitative" },
  { id: "mixed_methods", label: "Mixed-methods" },
  { id: "laboratory", label: "Laboratory" },
  { id: "basic_science", label: "Basic Science" },
  { id: "data_science", label: "Data Science" },
  { id: "other", label: "Other" },
];

export const skills = [
  { id: "clinical", label: "Clinical" },
  { id: "design", label: "Design" },
  { id: "project_management", label: "Project management" },
  { id: "software_engineering", label: "Software engineering" },
  { id: "other_engineering", label: "Other engineering" },
  { id: "other", label: "Other" },
];

export const hoursPerWeek = [
  { id: "1-5 hours", label: "1-5 hours per week" },
  { id: "6-10 hours", label: "6-10 hours per week" },
  { id: "11-20 hours", label: "11-20 hours per week" },
  { id: "21-40 hours", label: "21-40 hours per week" },
  { id: "ad_hoc", label: "Ad hoc requests here and there" },
];

export const numberOfHelpers = [
  { id: "1", label: "1 person" },
  { id: "2", label: "2 people" },
  { id: "3", label: "3 people" },
  { id: "4", label: "4 people" },
  { id: "5", label: "5 people" },
  { id: "6-10", label: "6-10 people" },
  { id: "10+", label: "10+ people" },
];
export const affiliations = [
  { id: "undergrad", label: "Undergraduate student" },
  { id: "grad", label: "Graduate student" },
  { id: "ms_pre_clinical", label: "Medical student pre-clinical" },
  { id: "ms_clinical", label: "Medical student clinical" },
  { id: "resident", label: "UPHS resident" },
  { id: "staff", label: "University staff" },
  { id: "faculty", label: "University faculty" },
  { id: "alum", label: "University alumni" },
  { id: "high_school", label: "High school student" },
];

export const yesNo = [
  { id: "true", label: "Yes" },
  { id: "false", label: "No" },
];

export function formatAffiliation(id: string) {
  const def = affiliations.find((a) => a.id === id);
  if (!def) return "";
  // if (def.text) return ... todo handle ms_Clinical
  return def.label;
}
export function formatAffiliations(selected: string[]) {
  return (selected || []).map(formatAffiliation).join(", ");
}
export function formatSkill(id: string) {
  const def = skills.find((s) => s.id === id);
  if (!def) return "";
  return def.label;
}
export function formatSkills(selected: string[]) {
  return (selected || []).map(formatSkill).join(", ");
}
export function formatGrade(id: string) {
  const def = grades.find((s) => s.id === id);
  if (!def) return "";
  return def.label;
}
export function formatGrades(selected: string[]) {
  return (selected || []).map(formatGrade).join(", ");
}
export function formatUndergradProjectType(id: string) {
  const def = undergradProjectTypes.find((s) => s.id === id);
  if (!def) return "";
  return def.label;
}
export function formatUndergradProjectTypes(selected: string[]) {
  return (selected || []).map(formatUndergradProjectType).join(", ");
}

export function formatCategory(id: string) {
  const def = categories.find((c) => c.id === id);
  if (id && id.indexOf("other-") !== -1) {
    return id.replace("other-", "");
  }
  if (!def) return "";
  return def.label;
}
export function formatDepartment(id: string) {
  const def = departments.find((c) => c.id === id);
  if (!def) return "";
  return def.label;
}
export function formatIrbCategory(id: string) {
  const def = irbCategories.find((c) => c.id === id);
  if (id.indexOf("other-") !== -1) {
    return id.replace("other-", "");
  }
  if (!def) return "";
  return def.label;
}
export function formatCategories(selected: string[]) {
  return (selected || []).map(formatCategory).join(", ");
}
export function formatIrbCategories(selected: string[]) {
  return (selected || []).map(formatIrbCategory).join(", ");
}

export const projectStatusLabel = {
  has_need: "Help needed",
  no_need: "Help not needed",
  closed: "Project completed",
};

export function formatStatusOfProject({ project }: any) {
  return project.isUrgent && project.status === "has_need"
    ? "Urgent staffing needed"
    : // @ts-ignore
      projectStatusLabel[project.status];
}

export const departments = [
  { id: "other", label: "Other" },
  { id: "biochemistry_and_biophysics", label: "Biochemistry and Biophysics" },
  { id: "biostatistics_epidemiology_and_informatics", label: "Biostatistics, Epidemiology and Informatics" },
  { id: "cancer_biology", label: "Cancer Biology" },
  { id: "cell_and_developmental_biology", label: "Cell and Developmental Biology" },
  { id: "genetics", label: "Genetics" },
  { id: "medical_ethics_and_health_policy", label: "Medical Ethics and Health Policy" },
  { id: "microbiology", label: "Microbiology" },
  { id: "neuroscience", label: "Neuroscience" },
  { id: "physiology", label: "Physiology" },
  { id: "systems_pharmacology_and_translational_therapeutics", label: "Systems Pharmacology and Translational Therapeutics" },
  { id: "anesthesiology_and_critical_care", label: "Anesthesiology and Critical Care" },
  { id: "dermatology", label: "Dermatology" },
  { id: "emergency_medicine", label: "Emergency Medicine" },
  { id: "family_medicine_and_community_health", label: "Family Medicine and Community Health" },
  { id: "cardiovascular_medicine", label: "Cardiovascular Medicine" },
  { id: "endocrinology_diabetes_and_metabolism", label: "Endocrinology, Diabetes and Metabolism" },
  { id: "gastroenterology", label: "Gastroenterology" },
  { id: "general_internal_medicine", label: "General Internal Medicine" },
  { id: "geriatrics", label: "Geriatrics" },
  { id: "hematology_oncology", label: "Hematology/Oncology" },
  { id: "infectious_diseases", label: "Infectious Diseases" },
  { id: "pulmonary_and_critical_care_medicine", label: "Pulmonary and Critical Care Medicine" },
  { id: "renal_electrolyte_and_hypertension", label: "Renal-Electrolyte and Hypertension" },
  { id: "rheumatology", label: "Rheumatology" },
  { id: "sleep_medicine", label: "Sleep Medicine" },
  { id: "translational_medicine_and_human_genetics", label: "Translational Medicine and Human Genetics" },
  { id: "neurology", label: "Neurology" },
  { id: "neurosurgery", label: "Neurosurgery" },
  { id: "obstetrics_and_gynecology", label: "Obstetrics and Gynecology" },
  { id: "ophthalmology", label: "Ophthalmology" },
  { id: "orthopaedic_surgery", label: "Orthopaedic Surgery" },
  { id: "otorhinolaryngology_head_and_neck_surgery", label: "Otorhinolaryngology - Head and Neck Surgery" },
  { id: "pathology_and_laboratory_medicine", label: "Pathology and Laboratory Medicine" },
  { id: "pediatrics", label: "Pediatrics" },
  { id: "physical_medicine_and_rehabilitation", label: "Physical Medicine and Rehabilitation" },
  { id: "psychiatry", label: "Psychiatry" },
  { id: "radiation_oncology", label: "Radiation Oncology" },
  { id: "radiology", label: "Radiology" },
  { id: "cardiovascular_surgery", label: "Cardiovascular Surgery" },
  { id: "colon_and_rectal_surgery", label: "Colon and Rectal Surgery" },
  { id: "endocrine_and_oncologic_surgery", label: "Endocrine and Oncologic Surgery" },
  { id: "gastrointestinal_surgery", label: "Gastrointestinal Surgery" },
  { id: "pediatric_surgery", label: "Pediatric Surgery" },
  { id: "plastic_surgery", label: "Plastic Surgery" },
  { id: "thoracic_surgery", label: "Thoracic Surgery" },
  { id: "transplant_surgery", label: "Transplant Surgery" },
  { id: "traumatology_surgical_critical_care_and_emergency_surgery", label: "Traumatology, Surgical Critical Care and Emergency Surgery" },
  { id: "urology", label: "Urology" },
  { id: "vascular_surgery_and_endovascular_therapy", label: "Vascular Surgery & Endovascular Therapy" },
];

// Organized by ids from departments. Use these ids to look up label from above
export interface ISubcategoryDisplay {
  /**
   * Label to use for display of a subcategory root label
   */
  rootLabel: string;
  /**
   * These strings must match up with IDs from departments
   */
  items: string[];
}
export const departmentsDisplay: Array<string | ISubcategoryDisplay> = [
  "other",
  // Basic Science
  "biochemistry_and_biophysics",
  "biostatistics_epidemiology_and_informatics",
  "cancer_biology",
  "cell_and_developmental_biology",
  "genetics",
  "medical_ethics_and_health_policy",
  "microbiology",
  "neuroscience",
  "physiology",
  "systems_pharmacology_and_translational_therapeutics",
  // Clinical
  "anesthesiology_and_critical_care",
  "dermatology",
  "emergency_medicine",
  "family_medicine_and_community_health",
  {
    rootLabel: "Medicine",
    items: [
      "cardiovascular_medicine",
      "endocrinology_diabetes_and_metabolism",
      "gastroenterology",
      "general_internal_medicine",
      "geriatrics",
      "hematology_oncology",
      "infectious_diseases",
      "pulmonary_and_critical_care_medicine",
      "renal_electrolyte_and_hypertension",
      "rheumatology",
      "sleep_medicine",
      "translational_medicine_and_human_genetics",
    ],
  },
  "neurology",
  "neurosurgery",
  "obstetrics_and_gynecology",
  "ophthalmology",
  "orthopaedic_surgery",
  "otorhinolaryngology_head_and_neck_surgery",
  "pathology_and_laboratory_medicine",
  "pediatrics",
  "physical_medicine_and_rehabilitation",
  "psychiatry",
  "radiation_oncology",
  "radiology",
  {
    rootLabel: "Surgery",
    items: [
      "cardiovascular_surgery",
      "colon_and_rectal_surgery",
      "endocrine_and_oncologic_surgery",
      "gastrointestinal_surgery",
      "pediatric_surgery",
      "plastic_surgery",
      "thoracic_surgery",
      "transplant_surgery",
      "traumatology_surgical_critical_care_and_emergency_surgery",
      "urology",
      "vascular_surgery_and_endovascular_therapy",
    ],
  },
];

export const sitePermissions = [
  { id: "admin", label: "Admin" },
  { id: "mentors", label: "Mentors" },
  { id: "resources", label: "Resources" },
  { id: "users", label: "Users" },
  { id: "projects", label: "Projects" },
];

export const projectPermissions = [
  { id: "curf", label: "Undergrad Projects" },
  { id: "radiologyResidents", label: "Radiology Residents Projects" },
  { id: "agnew", label: "Agnew Surgical Society Projects" },
];

export const domains = [
  { id: "curf", label: "Undergraduate" },
  { id: "agnew", label: "Agnew Surgical Society Project" },
  { id: "radiologyResidents", label: "Radiology Residents Project" },
  { id: "pennMedicine", label: "Penn Medicine - Other" },
  { id: "other", label: "Other" },
];

export const grades = [
  { id: "first_year", label: "First-Year" },
  { id: "second_year", label: "Second-Year" },
  { id: "junior", label: "Junior" },
  { id: "senior", label: "Senior" },
];

export const undergradProjectTypes = [
  { id: "work_study", label: "Work-study" },
  { id: "salaried", label: "Salaried" },
  { id: "independent_study", label: "Independent Study" },
  { id: "volunteer", label: "Volunteer" },
];
