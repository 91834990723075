import React, { useState } from "react";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import _ from "lodash";

import { track } from "../etc/utils";
import feathers from "../etc/feathers-client";
import FileUpload from "./FileUpload";
import FormActionButtons from "./FormActionButtons";
import { categories } from "../etc/fields";
import FormTextField from "./FormTextField";
import SkillsSelect from "./SkillsSelect";
import HoursPerWeekSelect from "./HoursPerWeekSelect";
import AffiliationSelect from "./AffiliationSelect";
import RadioGroup from "./RadioGroup";

export default function ResourceForm({ resource }) {
  const [redirect, setRedirect] = useState(false);
  const [savedFiles, setSavedFiles] = useState();
  const [unsavedFiles, setUnsavedFiles] = useState();
  const { addToast } = useToasts();
  const { register, errors, handleSubmit, getValues, watch } = useForm({
    defaultValues: resource,
  });

  const deleteResource = async (id) => {
    let data = getValues();
    data.isDeleted = true;
    return updateResource(data);
  };

  const createResource = async (data) => {
    data.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    data.unsavedFileTitles = _.map(unsavedFiles, "title");

    try {
      await feathers.service("resources").create(data);
      addToast("Saved", { appearance: "success" });
      track({ category: "resources", action: "create" });
      setRedirect(true);
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  const updateResource = async (data) => {
    data.savedFiles = savedFiles;
    data.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    data.unsavedFileTitles = _.map(unsavedFiles, "title");
    try {
      await feathers.service("resources").patch(resource._id, data);
      addToast(data.isDeleted ? "Person deleted" : "Updated", {
        appearance: "success",
      });
      track({ category: "resources", action: "update" });
      setRedirect(true);
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  async function onSubmit(data) {
    if (resource._id) {
      await updateResource(data);
    } else {
      await createResource(data);
    }
  }

  React.useEffect(() => {
    setSavedFiles(resource.files);
  }, [resource]);

  if (redirect) {
    return <Redirect to={resource._id ? `/resource/${resource._id}` : "/resources"} />;
  }
  const status = watch("status");
  return (
    <Grid centered style={{ marginTop: 20 }}>
      <Grid.Column>
        <h1 style={{ marginTop: "1em" }}>{resource._id ? "Editing profile" : "Signing up"}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField name="name" label="Name" placeholder="Your full name" errors={errors} ref={register({ required: true, minLength: 2 })} />
          <FormTextField
            type="textarea"
            name="bio"
            label="Bio (include key phrases to help search)"
            placeholder="Tell us about yourself!"
            errors={errors}
            ref={register({ required: true, minLength: 2 })}
          />

          <AffiliationSelect label="Affiliation" errors={errors} ref={register({ required: true })} />

          <RadioGroup name="categories" options={categories} label="Interested in" errors={errors} ref={register({ required: true })} type="checkbox" />

          <SkillsSelect label="Skills" errors={errors} ref={register()} />
          <FormTextField
            name="skillsComment"
            label="Futher detail about skills"
            placeholder="E.g. MS3, MS4, or type of software engineering"
            errors={errors}
            ref={register()}
          />

          <div {...style.field}>
            <label {...style.fieldHeader} htmlFor="status">
              Attached files (e.g. resume)
            </label>
            <FileUpload unsavedFiles={unsavedFiles} savedFiles={savedFiles} setSavedFiles={setSavedFiles} setUnsavedFiles={setUnsavedFiles} />
          </div>

          <FormTextField
            name="email"
            type="email"
            label="Email"
            placeholder=""
            errors={errors}
            ref={register({
              required: true,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            })}
            requiredMessage="Please provide a contact email address."
            patternMessage="Invalid email address"
          />

          <FormTextField name="phone" label="Phone" errors={errors} ref={register()} />

          <FormTextField
            name="preferredContact"
            label="Preferred mode of communication"
            placeholder="E.g. call, text, email"
            errors={errors}
            ref={register()}
          />

          <div {...style.field} className={classnames({ error: errors.status })}>
            <label {...style.fieldHeader} htmlFor="status">
              Do you have capacity to join a project team that interests you?
            </label>
            <input name="status" type="radio" value="available" ref={register({ required: true })} />
            <span {...style.radioChoiceLabel}>I have capacity</span>
            <input name="status" type="radio" value="not_available" ref={register({ required: true })} />
            <span {...style.radioChoiceLabel}>I don't have capacity right now</span>

            {_.get(errors, "description.type") === "required" && <span className="error">Please indicate if you have capacity.</span>}
          </div>

          {status === "available" && <HoursPerWeekSelect label="Estimated weekly time involvement" errors={errors} ref={register()} />}

          <div {...style.field} className={classnames({ error: errors.notificationsEnabled })}>
            <label {...style.fieldHeader} htmlFor="notificationsEnabled">
              Get notified
            </label>
            <input id="notificationsEnabled" name="notificationsEnabled" type="checkbox" ref={register()} />
            <span>&nbsp; Email me when new projects are posted</span>
          </div>

          <FormActionButtons isEditing={resource._id} deleteFn={() => deleteResource(resource._id)} />
        </form>
      </Grid.Column>
    </Grid>
  );
}
const style = {
  radioChoiceLabel: css({
    margin: "0 10px",
    fontWeight: "normal",
  }),
  field: css({ margin: "20px 0" }),
  fieldHeader: css({
    color: "#229292",
    textTransform: "uppercase",
    display: "block",
    marginBottom: 5,
    fontSize: "0.9rem",
  }),
};
