import React from "react";
import { css } from "glamor";

export function Card({
  bannerText,
  title,
  status = "inactive",
  isDetailView = false,
  children,
  handleClick = null,
  editLink = null,
}) {
  return (
    <Container handleClick={handleClick} isDetailView={isDetailView}>
      <Banner status={status} isDetailView={isDetailView}>
        {bannerText}
      </Banner>
      <Body isDetailView={isDetailView}>
        {isDetailView ? (
          <div {...style.gridRow}>
            <div {...style.gridItem}>
              <Title isDetailView={isDetailView}>{title}</Title>
            </div>
            {editLink && <div {...style.gridItem}>{editLink}</div>}
          </div>
        ) : (
          <Title isDetailView={isDetailView}>{title}</Title>
        )}
        <div {...style.bodyContent}>{children}</div>
      </Body>
    </Container>
  );
}
export function Container({ handleClick, isDetailView = false, children }) {
  return (
    <div
      onClick={handleClick}
      {...css(
        style.container,
        handleClick ? style.containerForClicking : null,
        isDetailView ? style.containerForDetailView : style.containerForListView
      )}
    >
      {children}
    </div>
  );
}
export function Banner({ status, isDetailView = false, children }) {
  return (
    <div
      {...css(
        style.banner,
        isDetailView ? style.bannerForDetailView : style.bannerForListView,
        style.bannerForStatus[status]
      )}
    >
      {children}
    </div>
  );
}
export function Title({ children }) {
  return <div {...style.title}>{children}</div>;
}
export function Body({ children, isDetailView = false }) {
  return (
    <div
      {...css(
        style.body,
        isDetailView ? style.bodyForDetailView : style.bodyForListView
      )}
    >
      {children}
    </div>
  );
}
export function CardSection({ title, isDetailView = false, children }) {
  return (
    <div {...style.section}>
      <div {...style.sectionTitle}>{title}</div>
      <div {...style.sectionContent}>{children}</div>
    </div>
  );
}

const style = {
  container: css({}),

  containerForListView: css({
    borderRadius: 10,
    boxShadow:
      "0 1px 3px 0 rgba(0,0,0,0.20), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.14)",
  }),
  containerForClicking: css({
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0 3px 5px -1px rgba(0,0,0,0.20), 0 1px 18px 0 rgba(0,0,0,0.12), 0 6px 10px 0 rgba(0,0,0,0.14)",
    },
  }),
  banner: css({
    color: "white",
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
    display: 'flex'
  }),
  bannerForDetailView: {
    padding: "6px 25px",
    marginLeft: 0,
    display: "inline-block",
    borderRadius: 19,
  },
  bannerForListView: {
    padding: "15px 30px",
    borderRadius: "10px 10px 0 0",
  },
  bannerForStatus: {
    inactive: css({ backgroundColor: "#969696" }),
    active: css({ backgroundColor: "#67B2B2" }),
    urgent: css({ backgroundColor: "#B43E00" }),
  },
  bannerText: css({}),
  bodyForListView: css({ padding: 30 }),
  bodyForDetailView: css({ padding: "30px 0" }),
  bodyContent: css({ marginTop: 0 }),
  sectionContent: css({ lineHeight: "25px" }),
  title: css({
    color: "#323232",
    fontSize: 28,
    lineHeight: "28px",
    marginBottom: 10,
  }),
  caption: css({}),
  section: css({ padding: "15px 0" }),
  sectionTitle: css({
    fontSize: 14,
    textTransform: "uppercase",
    color: "#018080",
    marginBottom: 5,
  }),
  gridRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // margin: "0 -10px"
  }),
  gridItem: css({
    // margin: 10
  }),
};
