import React from 'react'
import {css} from 'glamor'

function Loading() {
    return (
        <div {...styles.loading}>Loading...</div>
    )
}

const styles = {
    loading: css({
        margin: '20px auto',
        fontSize: '1.2em'
    })
}

export default Loading
