import React, { useState, useEffect } from "react";
import { css } from "glamor";
import _ from "lodash";
import Button from "./Button";
import Modal from "./Modal";
import moment from "moment";

import { useCurrentUser } from "../context/current-user-context";
import useApplications from "../hooks/Applications";
import { checkRoleForService, formatPhone, pennIdIsAuthorizedFor } from "../etc/utils";
import {
  formatAffiliations,
  formatSkills,
  formatGrades,
  formatCategory,
  formatIrbCategories,
  formatStatusOfProject,
  formatDepartment,
  formatUndergradProjectTypes,
} from "../etc/fields";

import { Banner, CardSection } from "./Card";

import Link from "./Link";
import ShareProject from "./ShareProject";
import ApplicationSubmittedTag from "../components/ApplicationSubmittedTag";
import ApplyToProjectForm from "./ApplyToProjectForm";

const ApplicationWidget = ({ hasNeed, alreadyApplied, onClick, loading }) => {
  if (loading) return null;
  if (alreadyApplied) {
    return <ApplicationSubmittedTag dark />;
  } else if (hasNeed && !loading && alreadyApplied !== null) {
    return <Button onClick={onClick}>Interested? Message the project lead</Button>;
  }
  return null;
};

export default function ({ project }) {
  const [contactLeadModalOpen, setContactLeadModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(null);
  const { currentUser } = useCurrentUser();
  const { applications, loading: loadingApplications } = useApplications({
    userId: currentUser._id,
    toggle: contactLeadModalOpen, // using this to refetch after application gets submitted
  });

  // Determine if current user has already applied to this project
  useEffect(() => {
    if (!loadingApplications && applications.length) {
      const hasApplication = applications.filter((application) => application.projectId === project._id).length > 0;
      if (!hasApplication) setAlreadyApplied(false);
      else setAlreadyApplied(true);
    }
    if (!loadingApplications && applications.length === 0) {
      setAlreadyApplied(false);
    }
  }, [applications, loadingApplications, project]);

  if (!project) return <div>Loading...</div>;
  const canEdit = _.size(currentUser)
    ? currentUser._id === project.userId ||
      checkRoleForService(currentUser, "projects") ||
      pennIdIsAuthorizedFor({
        pennId: currentUser.pennId,
        authorizedPennIds: project.authorizedPennIds,
      })
    : false;

  return (
    <div>
      <Modal open={contactLeadModalOpen} requestClose={() => setContactLeadModalOpen(false)}>
        <ApplyToProjectForm project={project} requestClose={() => setContactLeadModalOpen(false)} />
      </Modal>
      <Modal open={shareModalOpen} requestClose={() => setShareModalOpen(false)}>
        <ShareProject project={project} requestClose={() => setShareModalOpen(false)} />
      </Modal>

      <div {...css(style.gridRow, style.navLinksRow)}>
        <div {...style.gridItem}>
          <Link to="/projects">{"<"} Back to all projects</Link>
        </div>
      </div>

      <div {...css(style.gridRow, style.navLinksRow)}>
        <Banner
          status={project.status === "has_need" && project.isUrgent ? "urgent" : project.status === "has_need" ? "active" : "inactive"}
          isDetailView={true}
        >
          {formatStatusOfProject({ project })}
        </Banner>
        <div {...style.spacer} />
        {canEdit && (
          <div {...style.gridItem}>
            <Link to={`/projects/edit/${project._id}`}>Edit project</Link>
            {/* <Link to={`/resources/edit/${resource._id}`}>Edit resource</Link> */}
          </div>
        )}

        <div style={{ textAlign: "right" }}>
          <ApplicationWidget
            hasNeed={project.status === "has_need"}
            alreadyApplied={alreadyApplied}
            loading={loadingApplications}
            onClick={() => setContactLeadModalOpen(true)}
          />
          <br />
          <Button onClick={() => setShareModalOpen(true)}>Share this link</Button>
        </div>
      </div>

      <div {...style.category}>{formatCategory(project.category)}</div>
      <div {...style.title}>{project.name}</div>

      <div {...style.info}>
        Posted on {moment(project.createdAt).format("M/D/YY [at] h:mma")}
        <span> by </span>
        {project.user.name}
      </div>

      {!!_.size(project.description) && (
        <CardSection title="Project description">
          <div {...style.description}>{project.description}</div>
        </CardSection>
      )}

      {!!_.size(project.files) && (
        <CardSection title="Project files">
          <div {...style.files}>
            {project.files.map((file) => (
              <div {...style.file} key={file.path}>
                &middot; <a href={file.path}>{file.title}</a>
              </div>
            ))}
          </div>
        </CardSection>
      )}

      {!!_.size(project.isClinical) && <CardSection title="Involves clinical care">{project.isClinical ? "yes" : "no"}</CardSection>}
      {!!_.size(project.irbCategories) && <CardSection title="IRB Research Categories">{formatIrbCategories(project.irbCategories)}</CardSection>}

      {!!_.size(project.endDate) && (
        <CardSection title="Project end date">
          <div {...style.endDate}>{project.endDate}</div>
        </CardSection>
      )}
      {!!_.size(project.responsibility) && (
        <CardSection title="Helper responsibility">
          <div {...style.responsibility}>{project.responsibility}</div>
        </CardSection>
      )}

      {!!_.size(project.affiliations) && <CardSection title="Preferred background">{formatAffiliations(project.affiliations)}</CardSection>}
      {project.domain === "curf" && !!_.size(project.undergradGrades) && (
        <CardSection title="Target year(s)">{formatGrades(project.undergradGrades)}</CardSection>
      )}
      {project.domain === "curf" && !!_.size(project.undergradTypes) && (
        <CardSection title="Type of project">{formatUndergradProjectTypes(project.undergradTypes)}</CardSection>
      )}

      {(!!_.size(project.skills) || !!project.skillsComment) && (
        <CardSection title="Preferred skills">
          {formatSkills(project.skills)}
          {!!project.skillsComment && <div>{project.skillsComment}</div>}
        </CardSection>
      )}

      {!!_.size(project.hoursPerWeek) && <CardSection title="Estimated weekly time involvement">{project.hoursPerWeek}</CardSection>}
      {!!_.size(project.headCount) && <CardSection title="Headcount">{project.headCount}</CardSection>}
      {!!_.size(project.department) && <CardSection title="Department">{formatDepartment(project.department)}</CardSection>}

      {!!_.size(project.budget) && <CardSection title="Budget">{project.budget}</CardSection>}

      {!!_.size(project.contactName) && <CardSection title="Contact name">{project.contactName}</CardSection>}
      {!!_.size(project.contactTitle) && <CardSection title="Title">{project.contactTitle}</CardSection>}
      {!!_.size(project.contactAffiliation) && <CardSection title="Affiliation">{project.contactAffiliation}</CardSection>}
      {!!_.size(project.email) && <CardSection title="Email">{project.email}</CardSection>}
      {!!_.size(project.phone) && <CardSection title="Phone">{formatPhone(project.phone)}</CardSection>}
      {!!_.size(project.preferredContact) && <CardSection title="Preferred mode of communication">{project.preferredContact}</CardSection>}
    </div>
  );
}
const style = {
  gridRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // margin: "0 -10px"
  }),
  spacer: css({
    flex: 1,
  }),
  navLinksRow: css({
    marginBottom: 30,
    alignItems: "center",
  }),
  gridItem: css({
    margin: "0 20px",
  }),
  info: css({
    color: "grey",
    size: 16,
    marginBottom: 10,
  }),
  needs: css({ fontWeight: "500", whiteSpace: "pre-wrap" }),
  description: css({ whiteSpace: "pre-wrap" }),
  responsibility: css({ whiteSpace: "pre-wrap" }),
  comments: css({ color: "grey", textAlign: "right" }),
  category: css({
    fontWeight: "300",
    textTransform: "uppercase",
    marginTop: 20,
  }),
  title: css({
    color: "#323232",
    fontSize: "2rem",
    lineHeight: "2.8rem",
    margin: "20px 0",
  }),
};
