import React from "react";
import { useHistory } from "react-router-dom";
import { css } from "glamor";
import { Bookmark } from "react-feather";
import _ from "lodash";

import { CurrentUserContext } from "../context/current-user-context";
import { Card, CardSection } from "./Card";
import Link from "../components/Link";
import { checkRoleForService } from "../etc/utils";
import { formatAffiliations, formatSkills, formatCategories } from "../etc/fields";

const statusLabel = {
  available: "Has capacity to help",
  not_available: "No capacity to help",
};

export default function MentorCard({ mentor, isDetailView = false, bookmarked, toggleBookmark }) {
  const [state] = React.useContext(CurrentUserContext);
  const history = useHistory();
  const isOwnedByUser = state.currentUser._id === mentor.userId;
  const canEdit = isOwnedByUser || checkRoleForService(state.currentUser, "mentors");

  const bookmarkColor = "#ffffff";

  return (
    <Card
      title={mentor.name}
      bannerText={
        <>
          <span>{statusLabel[mentor.status]}</span>
          <span {...style.spacer} />
          <Bookmark
            fill={bookmarked ? bookmarkColor : "none"}
            color={bookmarkColor}
            onClick={(e) => {
              e.stopPropagation();
              toggleBookmark(mentor);
            }}
          />
        </>
      }
      handleClick={isDetailView ? null : () => history.push(`/mentor/${mentor._id}`)}
      status={mentor.status === "available" ? "active" : "inactive"}
      isDetailView={isDetailView}
      editLink={canEdit ? <Link to={`/mentors/edit/${mentor._id}`}>Edit helper</Link> : null}
    >
      {!!mentor.bio && (
        <CardSection title="About">
          <div {...style.bio}>{_.truncate(mentor.bio, { length: 100 })}</div>
        </CardSection>
      )}
      {!!_.size(mentor.affiliations) && <CardSection title="Affiliation">{formatAffiliations(mentor.affiliations)}</CardSection>}
      {!!_.size(mentor.categories) && <CardSection title="Interested in">{formatCategories(mentor.categories)}</CardSection>}
      {!!_.size(mentor.skills) && <CardSection title="Skills">{formatSkills(mentor.skills)}</CardSection>}
      {mentor.status === "available" && !!mentor.hoursPerWeek && <CardSection title="Estimated weekly availability">{mentor.hoursPerWeek}</CardSection>}
      {!!mentor.email && <CardSection title="Contact">{mentor.email}</CardSection>}
    </Card>
  );
}
const style = {
  editLinkContainer: css({ color: "grey", textAlign: "right" }),
  bio: css({ whiteSpace: "pre-wrap" }),
  spacer: css({ flex: 1 }),
  info: css({
    color: "#C9C8C8",
    size: 16,
    marginBottom: 10,
  }),
};
