import React, { useEffect, useState } from "react";
import { css } from "glamor";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import feathers from "../etc/feathers-client";
import MentorList from "../components/MentorList";
import TextFilter from "../components/TextFilter";
import CategoryFilter from "../components/CategoryFilter";
import BookmarkFilter from "../components/BookmarkFilter";
import FilterCount from "../components/FilterCount";
import ShowMore from "..//components/ShowMore.tsx";
// import { filterMentor } from "../etc/filters";
import Button from "../components/Button";
import { useBookmarks } from "../hooks/Bookmarks";
import useDebounce from "../hooks/Debounce";
import { affiliations, hoursPerWeek, skills, categories } from "../etc/fields";
// Reformat capacityFilters to standard format
let capacityFilters = [].concat([], hoursPerWeek, [{ id: "not_available", label: "No capacity" }]);

const PAGE_LIMIT = 30;

const formatFiltersForBackend = ({ categoryFilters, bookmarks, filterText, filterBookmarks }) => {
  let filters = {};

  // Filter for category
  if (categoryFilters.interests && categoryFilters.interests.length) {
    filters.categories = {
      $in: categoryFilters.interests,
    };
  }

  // filter for status
  if (categoryFilters.skills && categoryFilters.skills.length) {
    filters.skills = {
      $in: categoryFilters.skills,
    };
  }

  // filter for affiliations
  if (categoryFilters.affiliation && categoryFilters.affiliation.length) {
    filters.affiliations = {
      $in: categoryFilters.affiliation,
    };
  }

  // filter for capacity
  if (categoryFilters.capacity && categoryFilters.capacity.length) {
    filters.$or = [
      {
        hoursPerWeek: {
          $in: categoryFilters.capacity, // ToDo: map??
        },
      },
    ];
    if (categoryFilters.capacity.indexOf("not_available") !== -1) {
      filters.$or.push({ status: "not_available" });
    }
  }
  // Bookmarks
  if (filterBookmarks) {
    const bookmarkIds = bookmarks.filter((b) => !b.isDeleted).map((b) => b.itemId);
    filters._id = {
      $in: bookmarkIds,
    };
  }

  // Search terms
  if (filterText && filterText.length) {
    filters.orRegex = [
      {
        name: {
          $search: filterText,
        },
      },
      {
        bio: {
          $search: filterText,
        },
      },
      {
        email: {
          $search: filterText,
        },
      },
    ];
  }
  return filters;
};

export default function MentorListPage() {
  const [filterText, setFilterText] = useState("");
  const [categoryFilters, setCategoryFilters] = useState({});
  const [filterBookmarks, setFilterBookmarks] = useState(false);
  const [queryTotalMentors, setQueryTotalMentors] = useState(0);
  const [totalMentors, setTotalMentors] = useState(0);
  const clearFilters = () => {
    setCategoryFilters({});
    setFilterText("");
    setFilterBookmarks(false);
  };
  const debouncedFilterText = useDebounce(filterText, 200);
  const [mentors, setMentors] = useState([]);
  const history = useHistory();
  const { bookmarks, toggleBookmark } = useBookmarks({ type: "mentor" });

  async function loadData() {
    try {
      const backendFilters = formatFiltersForBackend({ categoryFilters, filterText, bookmarks, filterBookmarks });
      const mentorPage = await feathers.service("mentors").find({
        query: {
          ...backendFilters,
          isDeleted: null,
          $sort: { createdAt: -1 },
          $limit: PAGE_LIMIT, //todo. add pagination
        },
      });
      setQueryTotalMentors(mentorPage.total);
      setMentors(mentorPage.data);
    } catch (e) {
      console.log("error", e);
    }
  }

  async function requestNextPage() {
    try {
      const backendFilters = formatFiltersForBackend({ categoryFilters, filterText, bookmarks, filterBookmarks });
      const mentorPage = await feathers.service("mentors").find({
        query: {
          ...backendFilters,
          isDeleted: null,
          $sort: { createdAt: -1 },
          $skip: mentors.length,
          $limit: PAGE_LIMIT, //todo. add pagination
        },
      });
      setQueryTotalMentors(mentorPage.total);
      setMentors([...mentors, ...mentorPage.data]);
    } catch (e) {
      console.log("error", e);
    }
  }

  useEffect(() => {
    loadData();
  }, [categoryFilters, debouncedFilterText, filterBookmarks, bookmarks]);

  // onload, get total number of mentors
  async function getMentorCount() {
    try {
      const mentorPage = await feathers.service("mentors").find({
        query: {
          isDeleted: null,
          $limit: 0, //todo. add pagination
        },
      });

      setTotalMentors(mentorPage.total);
    } catch (e) {
      console.log(e);
    }
  }

  // get project count once on load
  useEffect(() => {
    getMentorCount();
  }, []);

  const mentorsWithUserData = mentors.map((mentor) => {
    const bookmarkIdx = bookmarks.map((b) => b.itemId).indexOf(mentor._id);
    const bookmarked = bookmarkIdx !== -1 && !bookmarks[bookmarkIdx].isDeleted;
    return {
      ...mentor,
      bookmarked,
    };
  });

  return (
    <div>
      <div {...style.actionRow}>
        <Button handleClick={() => history.push("/mentors/new")}>Add yourself as a faculty/mentor</Button>
        <div {...style.filterContainer}>
          <BookmarkFilter active={filterBookmarks} toggleBookmarks={() => setFilterBookmarks(!filterBookmarks)} />
          <TextFilter filterText={filterText} setFilterText={setFilterText} label={"Search name, bio, or email"} />
          {/* <CategoryFilter
            categories={{
              affiliation: affiliations,
              interests: categories,
              skills: skills,
              capacity: capacityFilters,
            }}
            filters={categoryFilters}
            visualGroupings={[["affiliation"], ["interests"], ["skills"], ["capacity"]]}
            onUpdateFilters={(filters) => setCategoryFilters(filters)}
          /> */}
        </div>
      </div>

      <FilterCount alwaysShow active={queryTotalMentors} total={totalMentors} itemLabel="mentors" clearFiltersFn={clearFilters} />

      <MentorList mentors={_.sortBy(mentorsWithUserData, "status")} bookmarks={bookmarks} toggleBookmark={toggleBookmark} />

      {mentorsWithUserData.length < queryTotalMentors && <ShowMore onClick={() => requestNextPage()} />}
    </div>
  );
}

const style = {
  filterContainer: css({
    display: "flex",
    maxWidth: "100%",
    "@media (max-width: 500px)": { marginTop: 10 },
  }),
  actionRow: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0",
    flexWrap: "wrap",
  }),
};
