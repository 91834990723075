import React, { useEffect, useState } from "react";
import { css } from "glamor";
import {Link} from 'react-router-dom'
import feathers from "../etc/feathers-client";
import { Pagination } from "semantic-ui-react";

import {Table, Thead, Tbody, Tr, Th, Td} from '../components/Table';
import Loading from './Loading';
const PAGE_SIZE = 25;

export default function () {
  const [resources, setResources] = useState([]);
  const [resourcesTotal, setResourcesTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  
  async function requestPage(page) {
    try {
      setLoading(true);
      const resourcesQuery = await feathers.service("resources").find({
        query: {
          isDeleted: null,
          $sort: { createdAt: -1 },
          $skip: PAGE_SIZE * (page - 1),
          $limit: PAGE_SIZE, //todo. add pagination
        },
      });
      setResourcesTotal(resourcesQuery.total);
      setResources(resourcesQuery.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }
  
  // When page changes...
  useEffect(() => { requestPage(page); }, [page]);


  if (loading) {
    return <Loading />
  }

  return (
    <div {...styles.page}>
      <Table {...styles.table}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Created</Th>
            <Th>Contact Email</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          resources.map(resource => (
            <Tr key={resource._id}>
              <Td><Link to={`/resource/${resource._id}`}>{resource.name}</Link></Td>
              <Td>{new Date(resource.createdAt).toLocaleDateString()}</Td>
              <Td>{resource.email}</Td>
            </Tr>
          ))
        }
        </Tbody>
      </Table>
      { resourcesTotal > PAGE_SIZE &&
      <Pagination totalPages={Math.ceil(resourcesTotal / PAGE_SIZE)} defaultActivePage={1} onPageChange={(event, data) => setPage(data.activePage)} />
      }
    </div>
  );
}

const styles = {
  page: css({
    margin: "20px 0"
  }),
  table: css({
    marginBottom: 20
  })
};
