export function filterComment({ comment, filterText }) {
  if (comment.isDeleted) return false;
  if (!filterText) return true;
  if (matchesText({ text: comment.text, filterText })) return true;
  return false;
}

function matchesText({ text, filterText }) {
  const re = new RegExp(filterText, "i");
  return re.test(text);
}
