import React, { useState, useEffect, useRef } from 'react'
import { css } from 'glamor'
import _ from 'lodash'
import { ChevronDown } from 'react-feather'

import RadioGroup from './RadioGroup'
import useDebounce from '../hooks/Debounce'
import { usePrevious } from '../hooks/Previous'
import { debounce } from 'lodash'

interface IDropDownSelectProps {
    value?: any
    name: string
    label: string
    placeholder: string
    errors: object
    options: any[]
    style: object
    dropStyle: object
}

const DropdownSelect = React.forwardRef((props: IDropDownSelectProps, ref) => {
    const dropdownRef = useRef(null)
    const { value, name, dropStyle, label, errors, placeholder, options } = props
    const [open, setOpen] = useState(false)
    const prevOpen = usePrevious(open)
    const [filter, setFilter] = useState('')
    const debouncedFilter = useDebounce(filter, 150)
    const selected = options.filter(o => o.id === value)
    const selectedLabel = selected.length ? selected[0].label : null
    const onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") setOpen(true)
    }
    const filteredOptions = debouncedFilter.length
        ? options.filter(option => option.label.toLowerCase().includes(debouncedFilter.toLowerCase()))
        : options
            
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        e.stopPropagation()
        setFilter(e.target.value)
    }

    // Close the dropdown on an clicks that originate outside the parent node
    useEffect(() => {
        const closeOnOutsideClick = (event: MouseEvent):any => {
            // @ts-ignore
            if (open && dropdownRef.current !== null && !dropdownRef.current.contains(event.target)) {
                setOpen(false)
            }
        }
        document.addEventListener('mousedown', closeOnOutsideClick)

        return () => {
            document.removeEventListener('mousedown', closeOnOutsideClick)
        }
    }, [dropdownRef, open])

    // If user reopens the dropdown, make sure to clear the filter
    useEffect(() => {
        if (open && !prevOpen && debouncedFilter.length) {
            setFilter('')
        }
    }, [open, prevOpen, setFilter, debouncedFilter])

    return (
        <div {...css(style.field, props.style)} onKeyPress={onKeyPress} ref={dropdownRef}>
            <label {...style.fieldHeader} htmlFor={name}>
                {label}
            </label>
            <div
            tabIndex={0}
            {...css(style.dropBtn, (selectedLabel && style.selectedText))}
            onClick={() => setOpen(!open)}>
                <span>{selectedLabel ? selectedLabel : placeholder}</span>
                <div {...style.spacer } />
                <ChevronDown />
            </div>
            <div
            className="dropdown-content"
            style={{ ...dropStyle, ...{ display: open ? 'block' : 'none' }}}>
                {options.length > 10 &&
                <input
                {...style.filter}
                tabIndex={0}
                placeholder="Filter"
                value={filter}
                onChange={onSearchInputChange} />}
                <RadioGroup
                    // @ts-ignore
                    name={name}
                    ref={ref}
                    options={filteredOptions}
                    errors={errors}
                    style={{margin: 0}}
                />
                <br />
            </div>
            {_.get(errors, [name, "type"]) === "required" && (
                <span className="error">Please specify</span>
            )}
        </div>
    )
})

const style = {
  filter: css({
      margin: '8px 8px 0 0',
      padding: 8,
      width: '100%',
      border: '1px solid #969696',
  }),
  field: css({ margin: "20px 0 50px 0" }),
  fieldHeader: css({
    fontSize: '20px', 
    color: '#323232',
    fontWeight: 'bold',
    display: "block",
    marginBottom: 20
  }),
  dropBtn: css({
      border: '1px solid #969696',
      borderRadius: 5,
      color: "#C9C8C8",
      padding: "0.625rem 1.25rem 0.625rem 1.25rem",
      display: 'flex',
      width: '100%'
  }),
  selectedText: css({
      color: "#323232"
  }),
  spacer: css({
      flex: 1
  })
}

export default DropdownSelect