import React from "react";
import { css } from "glamor";
import _ from "lodash";

const TextInput = React.forwardRef((props, ref) => {
  return (
    <input
      {...style.input}
      ref={ref}
      aria-invalid={_.get(props.errors, [props.name, "type"]) === "maxLength"}
      maxLength={props.maxLength ? props.maxLength : undefined}
      {...props}
    />
  );
});

export default TextInput;

const style = {
  input: css({
    lineHeight: "1.2rem",
    fontSize: "1em",
    padding: ".6em .8em",
    marginBottom: 30,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid rgba(34,36,38,.15)",
    color: "rgba(0,0,0,.87)",
    borderRadius: 1,
    boxShadow: "0 0 0 0 transparent inset",
    transition: "color .1s ease,border-color .1s ease",
    width: "100%",
    verticalAlign: "top",
    WebkitAppearance: "none",
    WebkitTapHighlightColor: "rgba(255,255,255,0)",
    ":focus": {
      padding: ".6em",
    },
  }),
};
