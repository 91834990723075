import React, { useEffect, useState } from "react";
import _ from "lodash";
import ResourceForm from "../components/ResourceForm";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";

import feathers from "../etc/feathers-client";

export default function ResourceFormPage() {
  const { addToast } = useToasts();
  const [resource, setResource] = useState();
  let { resourceId } = useParams();
  useEffect(() => {
    (async function loadResource() {
      try {
        setResource(resourceId ? await feathers.service("resources").get(resourceId) : {});
      } catch (error) {
        addToast(_.get(error, "response.data.message", error.message), {
          appearance: "error",
        });
      }
    })();
  }, [resourceId]);

  if (!resource) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <ResourceForm resource={resource} />
    </div>
  );
}
