import ReactGA from "react-ga";

export function truncateText({ text, chars = 500 }) {}

export function formatPhone(phone) {
  if (!phone) return;
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else {
    return phone;
  }
}
export function checkRoleForService(user, serviceName) {
  if (!user.permissions) return false;
  if (user.permissions.includes("admin")) return true;
  if (serviceName === "users") return false;
  if (user.permissions.includes(serviceName)) return true;
  return false;
}

export function track({ category, action, label }) {
  ReactGA.event({ category, action, label });
  // eslint-disable-next-line
  _gs("event", `${category} ${action}`);
}

export function pennIdIsAuthorizedFor({ pennId, authorizedPennIds }) {
  console.log({ pennId, authorizedPennIds });
  return (
    !!pennId &&
    !!authorizedPennIds &&
    authorizedPennIds.match(pennId.toString())
  );
}
