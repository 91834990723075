import React, { useEffect, useState } from "react";
import { css } from "glamor";
import {Link} from 'react-router-dom'
import feathers from "../etc/feathers-client";
import { Pagination } from "semantic-ui-react";

import {Table, Thead, Tbody, Tr, Th, Td} from '../components/Table';
import Loading from './Loading';
import { useCurrentUser } from '../context/current-user-context';
const PAGE_SIZE = 25;

const queryProjects = (applications) => {
  return feathers.service("projects").find({
    query: {
      _id: { $in: applications.map(a => a.projectId )},
      isDeleted: null,
      $sort: { createdAt: -1 },
      $limit: 1000
    },
  });
}

const queryResources = (applications) => {
  return feathers.service("resources").find({
    query: {
      userId: { $in: applications.map(a => a.userId )},
      isDeleted: null,
      $sort: { createdAt: -1 },
      $limit: 1000
    },
  });
}

const queryApplications = (page) => {
  return feathers.service("applications").find({
    query: {
      $skip: PAGE_SIZE * (page - 1),
      $sort: { createdAt: -1 },
      $limit: PAGE_SIZE, //todo. add pagination
    },
  });
}

export default function () {
  const { currentUser, loading: loadingCurrentUser } = useCurrentUser();
  const [applications, setApplications] = useState([]);
  const [applicationsTotal, setApplicationsTotal] = useState(0);
  const [page, setPage] = useState(1)
  const [projects, setProjects] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  async function requestPage(page) {
    try {
      setLoading(true);
      const applicationsQuery = await queryApplications(page)
      const [projectsQuery, resourcesQuery] = await Promise.all([
        queryProjects(applicationsQuery.data), queryResources(applicationsQuery.data)
      ])
      setApplications(applicationsQuery.data);
      setProjects(projectsQuery.data);
      setResources(resourcesQuery.data)
      setApplicationsTotal(applicationsQuery.total)
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  // Request new page
  useEffect(() => { requestPage(page); }, [page]);

  if (loading || loadingCurrentUser) {
    return <Loading />
  }

  const hasResourcePermission = currentUser.permissions.indexOf('resources') !== -1;
  const admin = currentUser.permissions.indexOf('admin') !== -1;

  return (
    <div {...styles.page}>
      <Table {...styles.table}>
        <Thead>
          <Tr>
            {(admin || hasResourcePermission) && <Th>Applicant Name</Th>}
            {(admin || hasResourcePermission) && <Th>Applicant Email</Th>}
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th>Project Name</Th>
            <Th>Project Email</Th>
            {(admin || hasResourcePermission) && <Th>Applicant</Th>}
            <Th>Project</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          applications.map(application => {
            const project = projects.filter(p => p._id === application.projectId)[0];
            const applicant = (admin || hasResourcePermission)
              ? resources.filter(r => r.userId === application.userId)[0]
              : null;
            // Shouldn't hit this condition but if there's something wrong with this record
            // don't prevent the rendering of other records
            if (!project || !application) return null;
            return (
              <Tr key={application._id}>
                {(admin || hasResourcePermission) && (applicant ? <Td>{applicant.name}</Td> : <Td />)}
                {(admin || hasResourcePermission) && (applicant ? <Td>{applicant.email}</Td> : <Td />)}
                <Td>{application.status}</Td>
                <Td>{new Date(application.createdAt).toLocaleString()}</Td>
                <Td>{new Date(application.updatedAt).toLocaleString()}</Td>
                {project ? <Td>{project.name}</Td> : <Td />}
                {project ? <Td>{project.email}</Td> : <Td />}
                {(admin || hasResourcePermission) && (applicant ? <Td><Link to={`/resource/${applicant._id}`}>{applicant.name}</Link></Td> : <Td />)}
                {project ? <Td><Link to={`/project/${project._id}`}>{project.name}</Link></Td> : <Td />}
              </Tr>
            )
          })
        }
        </Tbody>
      </Table>
      { applicationsTotal > PAGE_SIZE &&
      <Pagination totalPages={Math.ceil(applicationsTotal / PAGE_SIZE)} defaultActivePage={1} onPageChange={(event, data) => setPage(data.activePage)} />
      }
    </div>
  );
}

const styles = {
  page: css({
    margin: "20px 0"
  }),
  table: css({
    marginBottom: 20
  })
};
