import React, { useEffect, useState } from "react";
import { css } from "glamor";
import feathers from "../etc/feathers-client";
import MentorDetail from "../components/MentorDetail";

export default function ({ match }) {
  const { mentorId } = match.params; // From URL
  const [mentor, setMentor] = useState(null);

  useEffect(() => {
    (async function runOnce() {
      setMentor(await feathers.service("mentors").get(mentorId));
    })();
  }, [mentorId]);

  if (mentor && mentor.isDeleted) return <h3>That mentor was deleted.</h3>;
  return (
    <div style={{}}>
      <div {...style.detailContainer}>{!!mentor && <MentorDetail mentor={mentor} />}</div>
    </div>
  );
}

const style = {
  detailContainer: css({ margin: "40px 0" }),
  textFilter: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
};
