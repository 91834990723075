import React from "react";
import { css } from "glamor";

export default function Alert({ children, kind }) {
  return <div {...css(style.container.all, style.container[kind])}>{children}</div>;
}

const style = {
  container: {
    all: css({ padding: "8px 12px", margin: "12px 0" }),
    positive: css({ background: "#CFFED4", border: "1px solid #04703C", color: "#04703C" }),
    warning: css({ background: "#FFEACA", border: "1px solid #aaa", color: "#57524E" }),
    negative: css({ background: "#FBEFEE", border: "1px solid #A13226", color: "#A13226" }),
  },
};
