import React from "react";
import { css } from "glamor";
import _ from "lodash";

import { CurrentUserContext } from "../context/current-user-context";
import { checkRoleForService, formatPhone } from "../etc/utils";
import { formatAffiliations, formatSkills, formatCategories } from "../etc/fields";

import { Banner, CardSection } from "./Card";
import Link from "../components/Link";

const statusLabel = {
  available: "Has capacity to help",
  not_available: "No capacity to help",
};

export default function ({ mentor }) {
  const [state] = React.useContext(CurrentUserContext);

  if (!mentor) return <div>Loading...</div>;

  const isOwnedByUser = state.currentUser._id === mentor.userId;
  const canEdit = isOwnedByUser || checkRoleForService(state.currentUser, "mentors");

  return (
    <div>
      <div {...css(style.gridRow, style.navLinksRow)}>
        <div {...style.gridItem}>
          <Link to="/mentors">{"<"} Back to all mentors</Link>
        </div>
        {canEdit && (
          <div {...style.gridItem}>
            <Link to={`/mentors/edit/${mentor._id}`}>Edit</Link>
          </div>
        )}
      </div>

      <Banner status={mentor.status === "has_need" ? "active" : "inactive"} isDetailView={true}>
        {statusLabel[mentor.status]}
      </Banner>

      <div {...style.title}>{mentor.name}</div>

      {!!_.size(mentor.bio) && (
        <CardSection title="About">
          <div {...style.bio}>{mentor.bio}</div>
        </CardSection>
      )}

      {!!_.size(mentor.affiliations) && <CardSection title="Affiliation">{formatAffiliations(mentor.affiliations)}</CardSection>}
      {!!_.size(mentor.categories) && <CardSection title="Interested in">{formatCategories(mentor.categories)}</CardSection>}

      {(!!mentor.skills || !!mentor.skillsComment) && (
        <CardSection title="Skills">
          {formatSkills(mentor.skills)}
          {!!_.size(mentor.skillsComment) && <div>{mentor.skillsComment}</div>}
        </CardSection>
      )}

      {!!_.size(mentor.files) && (
        <CardSection title="Attached files">
          <div {...style.files}>
            {mentor.files.map((file) => (
              <div {...style.file} key={file.path}>
                &middot; <a href={file.path}>{file.title}</a>
              </div>
            ))}
          </div>
        </CardSection>
      )}

      {mentor.status === "available" && !!mentor.hoursPerWeek && <CardSection title="Estimated weekly availability">{mentor.hoursPerWeek}</CardSection>}

      {!!_.size(mentor.email) && <CardSection title="Email">{mentor.email}</CardSection>}
      {!!_.size(mentor.phone) && <CardSection title="Phone">{formatPhone(mentor.phone)}</CardSection>}
      {!!_.size(mentor.preferredContact) && <CardSection title="Preferred mode of communication">{mentor.preferredContact}</CardSection>}
    </div>
  );
}
const style = {
  gridRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // margin: "0 -10px"
  }),
  navLinksRow: css({
    marginBottom: 30,
    maxWidth: 500,
  }),
  gridItem: css({
    // flexBasis: "48%"
  }),
  info: css({
    color: "grey",
    size: 16,
    marginBottom: 10,
  }),
  needs: css({ fontWeight: "500", whiteSpace: "pre-wrap" }),
  description: css({ whiteSpace: "pre-wrap" }),
  responsibility: css({ whiteSpace: "pre-wrap" }),
  comments: css({ color: "grey", textAlign: "right" }),
  category: css({
    fontWeight: "300",
    textTransform: "uppercase",
    marginTop: 20,
  }),
  title: css({
    color: "#323232",
    fontSize: "2rem",
    lineHeight: "2.8rem",
    margin: "20px 0",
  }),
};
