import React, { useState } from "react";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { CurrentUserContext } from "../context/current-user-context";

import { track } from "../etc/utils";
import feathers from "../etc/feathers-client";
import FileUpload from "./FileUpload";
import FormActionButtons from "./FormActionButtons";
import { categories } from "../etc/fields";
import FormTextField from "./FormTextField";
import SkillsSelect from "./SkillsSelect";
import HoursPerWeekSelect from "./HoursPerWeekSelect";
import AffiliationSelect from "./AffiliationSelect";
import RadioGroup from "./RadioGroup";
import Button from "./Button";

import { useHistory } from "react-router-dom";

export default function UserForm({ user }) {
  const [state, dispatch] = React.useContext(CurrentUserContext);
  const [redirect, setRedirect] = useState(false);
  const { addToast } = useToasts();
  const { register, errors, handleSubmit, getValues, watch } = useForm({
    defaultValues: user,
  });

  // UPDATES -------------------
  let history = useHistory();

  const updateUser = async (data) => {
    try {
      const currentUser = await feathers.service("users").patch(user._id, data);
      dispatch({
        type: "FETCH_CURRENT_USER",
        payload: currentUser,
      });
      addToast("Saved", { appearance: "success" });
      setRedirect(true);
      history.goBack();
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  async function onSubmit(data) {
    await updateUser(data);
  }

  if (redirect) {
    // return history.goBack()
    return <Redirect to="/projects" />
  }
  const status = watch("status");
  return (
    <Grid centered style={{ marginTop: 20 }}>
      <Grid.Column>
        <h1 style={{ marginTop: "1em" }}>Editing profile for {user.name}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            name="email"
            type="email"
            label="Email"
            placeholder=""
            errors={errors}
            ref={register({
              required: true,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            })}
            requiredMessage="Please provide a contact email."
            patternMessage="Invalid email address"
          />

          <Grid.Column floated="left">
            <Button type="submit">Save</Button>
          </Grid.Column>
        </form>
      </Grid.Column>
    </Grid>
  );
}
const style = {
  radioChoiceLabel: css({
    margin: "0 10px",
    fontWeight: "normal",
  }),
  field: css({ margin: "20px 0" }),
  fieldHeader: css({
    color: "#229292",
    textTransform: "uppercase",
    display: "block",
    marginBottom: 5,
    fontSize: "0.9rem",
  }),
};
