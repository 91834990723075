import React from "react";
import { css } from "glamor";
import MentorCard from "./MentorCard";

export default function MentorList({ mentors, bookmarks, toggleBookmark }) {
  return (
    <div {...style.grid}>
      {mentors.map((mentor) => {
        const bookmark = bookmarks.filter((b) => b.itemId === mentor._id);
        const bookmarked = bookmark.length > 0 && !bookmark[0].isDeleted;
        return (
          <div {...style.card} key={mentor._id}>
            <MentorCard mentor={mentor} toggleBookmark={toggleBookmark} bookmarked={bookmarked} />
          </div>
        );
      })}
    </div>
  );
}

const style = {
  grid: css({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  }),
  card: css({
    margin: "20px 0",
    flexBasis: "48%",
    textLeft: "left",
    transition: "color 0.15s ease, border-color 0.15s ease",
    "@media (max-width: 480px)": {
      flexBasis: "100%",
    },
  }),
};
