import React from "react";
import { Grid } from "semantic-ui-react";
import Button from "./Button";
import { css } from "glamor";

export default function({ isEditing, deleteFn }) {
  return (
    <div {...style.row}>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column floated="left">
            <Button type="submit">Save</Button>
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            {isEditing && (
              <Button
                danger
                handleClick={e => {
                  e.preventDefault();
                  // eslint-disable-next-line
                  if (confirm("Are you sure?")) deleteFn();
                }}
              >
                Delete
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
const style = {
  row: css({
  })
};
