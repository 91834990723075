import React from 'react';
import { Bookmark } from 'react-feather'
import {css} from 'glamor'

interface IFilterProps {
    active: boolean,
    toggleBookmarks: () => void
}

interface IFilterButtonProps {
    onClick: () => void
    active: boolean
}

function FilterButton(props: IFilterButtonProps) {
    return (
        <button onClick={props.onClick} {...(props.active
            ? css(styles.filterBtn, styles.activeFilterBtn) // change the background when filters present
            : styles.filterBtn)
        }>
            <Bookmark {...styles.filterIcon } color={ props.active ? 'white' :  '#323232'}  strokeWidth="1" />
            <span { ...styles.btnLabel }>Bookmarks</span>
        </button>
    )
}


function Filter(props: IFilterProps) {

    return (
        <span {...styles.filter}>
            <FilterButton onClick={() => props.toggleBookmarks()} active={props.active} />
        </span>
    )
}

const styles = {
    filter: css({
        position: 'relative'
    }),
    filterBtn: css({
        borderRadius: '5px',
        border: '1px solid rgb(170, 170, 170)',
        position: 'relative',
        background: 'white',
        padding: '8px',
        margin: '0 4px',
        height: '55px',
        textAlign: 'center',
        lineHeight: 0.5,
        width: '66px',
        ':hover': {
            background: 'rgba(170, 170, 170, 0.1)'
        }
    }),
    activeFilterBtn: css({
        background: '#016766',
        color: 'white',
        ':hover': {
            background: '#67B4B3'
        }
    }),
    btnLabel: css({
        fontSize: 10
    }),
    filterIcon: css({
        position: 'relative',
        top: '-3px'
    }),
}

export default Filter
