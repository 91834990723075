import React from "react";
import { css } from "glamor";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { X as CloseIcon } from "react-feather";

async function getTitle({ files }) {
  let { value: title } = await Swal.fire({
    title: `Please describe the ${files.length === 1 ? "file" : "files"}`,
    input: "text",
    inputValidator: (value) => {
      if (!value) {
        return "This is required.";
      }
    },
  });
  if (!title) title = "Untitled";
  return { title };
}

function isUnsavedFile(file) {
  /* 
  return true if file was dropped into this form 
  rather than a pre-existing one from the db
  */
  return !!file.name;
}

export default function ({
  savedFiles = [],
  unsavedFiles = [],
  setSavedFiles,
  setUnsavedFiles,
}) {
  async function onDrop(files) {
    const { title } = await getTitle({ files });
    files.forEach((file) => {
      file.title = title;
    });

    setUnsavedFiles([...unsavedFiles, ...files]);
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    uploadMultiple: false,
  });

  async function removeFile(file) {
    if (isUnsavedFile(file)) {
      setUnsavedFiles(unsavedFiles.filter((f) => f.path !== file.path));
    } else {
      setSavedFiles(savedFiles.filter((f) => f.path !== file.path));
    }
  }

  function renderFile(file) {
    return (
      <div {...style.fileRow} key={file.path}>
        {isUnsavedFile(file) ? (
          file.title
        ) : (
          <a href={file.path}>
            {/* <a href={file.path} target="_blank" rel="noopener noreferrer"> */}
            {file.title}
          </a>
        )}
        <CloseIcon
          size={16}
          {...style.closeIcon}
          onClick={() => removeFile(file)}
        />
      </div>
    );
  }
  return (
    <section className="container">
      {savedFiles.map(renderFile)}
      {unsavedFiles.map(renderFile)}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>
          Drag and drop files here, or click to select files. Files are
          visible to all users.
        </p>
      </div>
      {/* <input type="file" /> */}
    </section>
  );
}
const style = {
  fileRow: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 16,
  }),
  closeIcon: css({ color: "maroon", marginLeft: 10 }),
};
