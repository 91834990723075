import React from "react";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";

import CommentCard from "./CommentCard";
import feathers from "../etc/feathers-client";

export default function CommentList({ comments, project }) {
  const { addToast } = useToasts();
  // const deletedIds = React.useRef([]);
  const deleteComment = (comment) => {
    comment.isDeleted = true;
    // const data = {
    //   ...comment,
    //   isDeleted: true,
    // };

    try {
      // Delete remote
      feathers
        .service("comments")
        .patch(comment._id, { isDeleted: true, projectId: project._id })
        .then((comment) => {
          // if (deletedIds) {
          //   deletedIds.current.push(comment._id);
          // }
          addToast("Comment deleted", { appearance: "success" });
        });
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        apperance: "error",
      });
    }
  };

  return comments.map((comment) => {
    return (
      <CommentCard
        key={comment._id}
        comment={comment}
        deleteComment={deleteComment}
      />
    );
  });
}
