import React from "react";
import { css } from "glamor";

import ProjectCard from "./ProjectCard";

export default function ProjectList({ projects, clickBookmarkProject, bookmarks }) {
  return (
    <div {...style.grid}>
      {projects.map(project => {
        const bookmark = bookmarks.filter(b => b.itemId === project._id);
        const bookmarked = bookmark.length > 0 && !bookmark[0].isDeleted;
        return (
          <div {...style.card} key={project._id}>
            <ProjectCard project={project} clickBookmarkProject={ clickBookmarkProject } bookmarked={bookmarked} />
          </div>
        )
      }
      )}
    </div>
  );
}

const style = {
  grid: css({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  }),
  card: css({
    margin: "20px 0",
    textLeft: "left",
    transition: "color 0.15s ease, border-color 0.15s ease",
    flexBasis: "48%",
    "@media (max-width: 480px)": {
      flexBasis: "100%"
    }
  })
};
