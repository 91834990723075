import React from "react";
import Button from "./Button";

export default function ({ composerText, setComposerText, handleSubmit }) {
  let inputRef;
  return (
    <div style={styles.row}>
      <input
        type="text"
        value={composerText}
        onChange={(e) => setComposerText(e.target.value)}
        name="composerText"
        autoComplete="no"
        placeholder="Add a comment..."
        style={styles.input}
        ref={(input) => {
          inputRef = input;
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSubmit();
        }}
      />
      <div>
        <Button handleClick={handleSubmit}>Post</Button>
      </div>
    </div>
  );
}

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    flex: 1,
    fontSize: "1.4rem",
    height: 60,
    padding: "12px 12px",
    border: "1px solid #aaa",
    borderRadius: 4,
    margin: "20px 20px 20px 0",
  },
};
