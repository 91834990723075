import React from "react";
import { useHistory } from "react-router-dom";
import { css } from "glamor";
import { Bookmark } from 'react-feather';
import _ from "lodash";

import { CurrentUserContext } from "../context/current-user-context";
import { Card, CardSection } from "./Card";
import Link from "../components/Link";
import { checkRoleForService } from "../etc/utils";
import {
  formatAffiliations,
  formatSkills,
  formatCategories,
} from "../etc/fields";

const statusLabel = {
  available: "Has capacity to help",
  not_available: "No capacity to help",
};

export default function ResourceCard({ resource, isDetailView = false, bookmarked, toggleBookmark }) {
  const [state] = React.useContext(CurrentUserContext);
  const history = useHistory();
  const isOwnedByUser = state.currentUser._id === resource.userId;
  const canEdit =
    isOwnedByUser || checkRoleForService(state.currentUser, "resources");

  const bookmarkColor = "#ffffff";

  return (
    <Card
      title={resource.name}
      bannerText={
        <>
          <span>{statusLabel[resource.status]}</span>
          <span {...style.spacer} />
          <Bookmark fill={bookmarked ? bookmarkColor : 'none'} color={bookmarkColor} onClick={e => {
            e.stopPropagation();
            toggleBookmark(resource);
          }} />
        </>
      }
      handleClick={
        isDetailView ? null : () => history.push(`/resource/${resource._id}`)
      }
      status={resource.status === "available" ? "active" : "inactive"}
      isDetailView={isDetailView}
      editLink={
        canEdit ? (
          <Link to={`/resources/edit/${resource._id}`}>Edit helper</Link>
        ) : null
      }
    >
      {!!resource.bio && (
        <CardSection title="About">
          <div {...style.bio}>{_.truncate(resource.bio, { length: 100 })}</div>
        </CardSection>
      )}
      {!!_.size(resource.affiliations) && (
        <CardSection title="Affiliation">
          {formatAffiliations(resource.affiliations)}
        </CardSection>
      )}
      {!!_.size(resource.categories) && (
        <CardSection title="Interested in">
          {formatCategories(resource.categories)}
        </CardSection>
      )}
      {!!_.size(resource.skills) && (
        <CardSection title="Skills">
          {formatSkills(resource.skills)}
        </CardSection>
      )}
      {resource.status === "available" && !!resource.hoursPerWeek && (
        <CardSection title="Estimated weekly availability">
          {resource.hoursPerWeek}
        </CardSection>
      )}
      {!!resource.email && (
        <CardSection title="Contact">{resource.email}</CardSection>
      )}
    </Card>
  );
}
const style = {
  editLinkContainer: css({ color: "grey", textAlign: "right" }),
  bio: css({ whiteSpace: "pre-wrap" }),
  spacer: css({ flex: 1 }),
  info: css({
    color: "#C9C8C8",
    size: 16,
    marginBottom: 10,
  }),
};
