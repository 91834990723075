import React from "react";
import { css } from "glamor";
import { Link } from "react-router-dom";

export default function ({ to, children }) {
  return (
    <Link {...style.link} to={to}>
      {children}
    </Link>
  );
}
const style = {
  link: css({ fontSize: 16, textDecoration: "underline", color: "grey" }),
};
