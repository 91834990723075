import React from "react";

import { undergradProjectTypes } from "../etc/fields";
import RadioGroup from "./RadioGroup";

const Field = React.forwardRef((props, ref) => {
  const { label, errors } = props;
  return (
    <RadioGroup
      name="undergradTypes"
      options={undergradProjectTypes}
      label={label}
      errors={errors}
      ref={ref}
      type="checkbox"
    />
  );
});
export default Field;
