import React from "react";
import { useHistory } from "react-router-dom";
import { Bookmark } from "react-feather";
import { css } from "glamor";
import _ from "lodash";
import moment from "moment";

import { formatCategory, formatGrades, formatStatusOfProject, formatUndergradProjectTypes } from "../etc/fields";
import { Card, CardSection } from "./Card";
import ApplicationSubmittedTag from "./ApplicationSubmittedTag";
import constants from "../constants";

export default function ({ project, clickBookmarkProject, bookmarked }) {
  const history = useHistory();
  if (!project) return <div>Loading...</div>;

  const userNameSplit = project.user ? project.user.name.replace(/ /g, "").split(",") : "";

  const bookmarkColor = "#ffffff";
  return (
    <Card
      title={project.name}
      bannerText={
        <>
          <span>{formatStatusOfProject({ project })}</span>
          <span {...style.spacer} />
          {project._currentUserApplied && <ApplicationSubmittedTag style={style.appliedTag} />}
          <Bookmark
            fill={bookmarked ? bookmarkColor : "none"}
            color={bookmarkColor}
            onClick={(e) => {
              e.stopPropagation();
              clickBookmarkProject(project);
            }}
          />
        </>
      }
      status={project.status === "has_need" && project.isUrgent ? "urgent" : project.status === "has_need" ? "active" : "inactive"}
      handleClick={() => history.push(`/project/${project._id}`)}
    >
      <div {...style.info}>
        Posted on {moment(project.createdAt).format("M/D/YY [at] h:mma")}
        <span> &middot; </span>
        {`${userNameSplit[1]} ${userNameSplit[0]}`}
      </div>
      <div {...style.gridRow}>
        {project.domain === "curf" && !!_.size(project.undergradTypes) && (
          <div {...style.gridItem}>
            <CardSection title="Project type">{formatUndergradProjectTypes(project.undergradTypes)}</CardSection>
          </div>
        )}
        {project.domain === "curf" && !!_.size(project.undergradGrades) && (
          <div {...style.gridItem}>
            <CardSection title="Year(s)">{formatGrades(project.undergradGrades)}</CardSection>
          </div>
        )}
        {!!project.category && (
          <div {...style.gridItem}>
            <CardSection title="Category">
              <div {...style.category}>{formatCategory(project.category)}</div>
            </CardSection>
          </div>
        )}
        <div {...style.gridItem}>{!!project.email && <CardSection title="Contact">{project.email}</CardSection>}</div>
      </div>

      {!!project.description && (
        <CardSection title="Project description">
          <div {...style.description}>{_.truncate(project.description, { length: constants.project.description.maxLength })}</div>
        </CardSection>
      )}

      {!!project.commentCount && (
        <div {...style.comments}>
          {project.commentCount} {project.commentCount === 1 ? "comment" : "comments"}
        </div>
      )}
    </Card>
  );
}
const style = {
  gridRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // margin: "0 -10px"
  }),
  gridItem: css({
    // flexBasis: "48%"
  }),
  info: css({
    color: "#C9C8C8",
    size: 16,
    marginBottom: 10,
  }),
  category: css({ fontWeight: "500", whiteSpace: "pre-wrap" }),
  description: css({ whiteSpace: "pre-wrap" }),
  comments: css({ color: "grey", textAlign: "right" }),
  appliedTag: css({
    justifyContent: "right",
  }),
  spacer: css({
    flex: 1,
  }),
};
