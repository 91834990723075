import React from 'react'
import { departments } from '../etc/fields'

import DropdownSelect from './DropdownSelect'

interface IDepartmetnDropdownSelect {
    label: string
    errors: object
    value?: string
}

const DepartmentDropdownSelect = React.forwardRef((props: IDepartmetnDropdownSelect, ref) => {
    const { label, errors, value } = props
    return (
        <DropdownSelect
        name="department"
        placeholder="Select a field"
        label={label}
        errors={errors}
        options={departments}
        ref={ref}
        value={value}
        style={{
            marginBottom: 240
        }}
        dropStyle={{
            maxHeight: 240,
            overflowY: 'auto',
            width: 'calc(100% - 32)',
            padding: '0 1em',
            left: 16
        }}
        />
    )
})

export default DepartmentDropdownSelect