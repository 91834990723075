import React from "react";
import { css } from "glamor";
import _ from "lodash";

import { CurrentUserContext } from "../context/current-user-context";
import { checkRoleForService, formatPhone } from "../etc/utils";
import {
  formatAffiliations,
  formatSkills,
  formatCategories,
} from "../etc/fields";

import { Banner, CardSection } from "./Card";
import Link from "../components/Link";

const statusLabel = {
  available: "Has capacity to help",
  not_available: "No capacity to help",
};

export default function ({ resource }) {
  const [state] = React.useContext(CurrentUserContext);

  if (!resource) return <div>Loading...</div>;

  const isOwnedByUser = state.currentUser._id === resource.userId;
  const canEdit =
    isOwnedByUser || checkRoleForService(state.currentUser, "resources");

  return (
    <div>
      <div {...css(style.gridRow, style.navLinksRow)}>
        <div {...style.gridItem}>
          <Link to="/resources">{"<"} Back to all helpers</Link>
        </div>
        {canEdit && (
          <div {...style.gridItem}>
            <Link to={`/resources/edit/${resource._id}`}>Edit</Link>
          </div>
        )}
      </div>

      <Banner
        status={resource.status === "has_need" ? "active" : "inactive"}
        isDetailView={true}
      >
        {statusLabel[resource.status]}
      </Banner>

      <div {...style.title}>{resource.name}</div>

      {!!_.size(resource.bio) && (
        <CardSection title="About">
          <div {...style.bio}>{resource.bio}</div>
        </CardSection>
      )}

      {!!_.size(resource.affiliations) && (
        <CardSection title="Affiliation">
          {formatAffiliations(resource.affiliations)}
        </CardSection>
      )}
      {!!_.size(resource.categories) && (
        <CardSection title="Interested in">
          {formatCategories(resource.categories)}
        </CardSection>
      )}

      {(!!resource.skills || !!resource.skillsComment) && (
        <CardSection title="Skills">
          {formatSkills(resource.skills)}
          {!!_.size(resource.skillsComment) && (
            <div>{resource.skillsComment}</div>
          )}
        </CardSection>
      )}

      {!!_.size(resource.files) && (
        <CardSection title="Attached files">
          <div {...style.files}>
            {resource.files.map((file) => (
              <div {...style.file} key={file.path}>
                &middot; <a href={file.path}>{file.title}</a>
              </div>
            ))}
          </div>
        </CardSection>
      )}

      {resource.status === "available" && !!resource.hoursPerWeek && (
        <CardSection title="Estimated weekly availability">
          {resource.hoursPerWeek}
        </CardSection>
      )}

      {!!_.size(resource.email) && (
        <CardSection title="Email">{resource.email}</CardSection>
      )}
      {!!_.size(resource.phone) && (
        <CardSection title="Phone">{formatPhone(resource.phone)}</CardSection>
      )}
      {!!_.size(resource.preferredContact) && (
        <CardSection title="Preferred mode of communication">
          {resource.preferredContact}
        </CardSection>
      )}
    </div>
  );
}
const style = {
  gridRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // margin: "0 -10px"
  }),
  navLinksRow: css({
    marginBottom: 30,
    maxWidth: 500,
  }),
  gridItem: css({
    // flexBasis: "48%"
  }),
  info: css({
    color: "grey",
    size: 16,
    marginBottom: 10,
  }),
  needs: css({ fontWeight: "500", whiteSpace: "pre-wrap" }),
  description: css({ whiteSpace: "pre-wrap" }),
  responsibility: css({ whiteSpace: "pre-wrap" }),
  comments: css({ color: "grey", textAlign: "right" }),
  category: css({
    fontWeight: "300",
    textTransform: "uppercase",
    marginTop: 20,
  }),
  title: css({
    color: "#323232",
    fontSize: "2rem",
    lineHeight: "2.8rem",
    margin: "20px 0",
  }),
};
