import React from "react";

import { affiliations } from "../etc/fields";
import RadioGroup from "./RadioGroup";
const Field = React.forwardRef((props, ref) => {
  const { label, errors } = props;
  return (
    <RadioGroup
      name="affiliations"
      options={affiliations}
      label={label}
      errors={errors}
      ref={ref}
      type="checkbox"
    />
  );
});
export default Field;
