import React from 'react'
import { css } from 'glamor'

function ApplicationSubmittedTag ({style, dark}) {
    return (
        <div {...css(styles.container, style)}>
            <img alt="checkmark" {...styles.checkmark} src={`/checkmark${!!dark ? '-dark' : ''}.svg`} /><span>applied</span>
        </div>
    )
}

const styles = {
    container: css({
        display: 'flex',
        alignItems: 'center',
        margin: '0 8px'
    }),
    checkmark: css({
        height: 24,
        marginRight: 6
    }),
}

export default ApplicationSubmittedTag
