import {useEffect, useState} from 'react'
import feathers from '../etc/feathers-client'
import { useCurrentUser } from '../context/current-user-context'

interface IBookmarkArgs {
    type: string
}

interface IBookmark {
    itemId: string,
    itemType: string,
    userId: string,
    isDeleted: boolean
}

export function useBookmarks({ type }: IBookmarkArgs) {
    const { currentUser } = useCurrentUser()
    const [bookmarks, setBookmarks] = useState([] as IBookmark[])
    const [loading, setLoading] = useState(true)
    // on load
    useEffect(() => {
        async function loadBookmarks() {
            try {
                const bookmarksReq = await feathers.service("bookmarks").find({
                    query: {
                        userId: currentUser._id,
                        $limit: 1000, //todo. add pagination
                    },
                })
                setBookmarks(bookmarksReq.data)
                setLoading(false)
            } catch (e) {
                console.log(e)
            }
        }
        loadBookmarks()
    }, [currentUser._id])

    // make an update
    async function toggleBookmark(item: any) {
        const idx = bookmarks.map(s => s.itemId).indexOf(item._id);
        const add = idx === -1;
        const isDeleted = !add && !bookmarks[idx].isDeleted;
        
        try {
        if (add) {
            const newBookmark: IBookmark = {
                userId: currentUser._id,
                itemType: type,
                itemId: item._id,
                isDeleted: false
            }
            // update optimistically
            setBookmarks([ ...bookmarks, newBookmark ])
            feathers.service("bookmarks").create(newBookmark)
        }
        else {
            // Tell the backend to set the deleted flag. We can keep these around for analytics
            // If it's the same session we might not have the id yet
            const bookmarkReq = await feathers.service("bookmarks").find({
                query: {
                    itemId: item._id
                }
            });

            const id = bookmarkReq.data.length
                ? bookmarkReq.data[0]._id
                : false;

            if (!id) return;
            
            feathers.service("bookmarks").patch(id, { isDeleted });

            // Update local state optimistically
            setBookmarks([
                ...bookmarks.slice(0, idx),
                { ...bookmarks[idx], isDeleted },
                ...bookmarks.slice(idx + 1)
            ]);
        }
        }
        catch (e) {
            console.log('error bookmarking item', e)
        }
    }

    return {
        bookmarks,
        loading,
        toggleBookmark
    }
}

export default useBookmarks
