import React from "react";

import { categories } from "../etc/fields";
import RadioGroup from "./RadioGroup";

const Field = React.forwardRef((props, ref) => {
  const { label, errors, value, register } = props;
  return (
    <RadioGroup
      value={value}
      register={register}
      allowUserInput
      name="category"
      options={categories}
      label={label}
      errors={errors}
      ref={ref}
      requiredMessage="Please choose a category"
    />
  );
});

export default Field;
