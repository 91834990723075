import React, { useState } from "react";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import _ from "lodash";

import { track } from "../etc/utils";
import feathers from "../etc/feathers-client";
import FileUpload from "./FileUpload";
import FormActionButtons from "./FormActionButtons";
import { categories } from "../etc/fields";
import FormTextField from "./FormTextField";
import SkillsSelect from "./SkillsSelect";
import HoursPerWeekSelect from "./HoursPerWeekSelect";
import AffiliationSelect from "./AffiliationSelect";
import RadioGroup from "./RadioGroup";

export default function MentorForm({ mentor }) {
  const [redirect, setRedirect] = useState(false);
  const [savedFiles, setSavedFiles] = useState();
  const [unsavedFiles, setUnsavedFiles] = useState();
  const { addToast } = useToasts();
  const { register, errors, handleSubmit, getValues, watch } = useForm({
    defaultValues: mentor,
  });

  const deleteMentor = async (id) => {
    let data = getValues();
    data.isDeleted = true;
    return updateMentor(data);
  };

  const createMentor = async (data) => {
    data.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    data.unsavedFileTitles = _.map(unsavedFiles, "title");

    try {
      await feathers.service("mentors").create(data);
      addToast("Saved", { appearance: "success" });
      track({ category: "mentors", action: "create" });
      setRedirect(true);
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  const updateMentor = async (data) => {
    data.savedFiles = savedFiles;
    data.unsavedFileBuffers = unsavedFiles; // these will get converted to buffers during form serialization
    data.unsavedFileTitles = _.map(unsavedFiles, "title");
    try {
      await feathers.service("mentors").patch(mentor._id, data);
      addToast(data.isDeleted ? "Person deleted" : "Updated", {
        appearance: "success",
      });
      track({ category: "mentors", action: "update" });
      setRedirect(true);
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  };

  async function onSubmit(data) {
    if (mentor._id) {
      await updateMentor(data);
    } else {
      await createMentor(data);
    }
  }

  React.useEffect(() => {
    setSavedFiles(mentor.files);
  }, [mentor]);

  if (redirect) {
    return <Redirect to={mentor._id ? `/mentor/${mentor._id}` : "/mentors"} />;
  }
  const status = watch("status");
  return (
    <Grid centered style={{ marginTop: 20 }}>
      <Grid.Column>
        <h1 style={{ marginTop: "1em" }}>{mentor._id ? "Editing profile" : "Creating profile"}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField name="name" label="Name" placeholder="Your full name" errors={errors} ref={register({ required: true, minLength: 2 })} />
          <FormTextField
            type="textarea"
            name="bio"
            label="Bio (include key phrases to help search)"
            errors={errors}
            ref={register({ required: true, minLength: 10 })}
          />

          <div {...style.field}>
            <label {...style.fieldHeader} htmlFor="status">
              Attached files (optional)
            </label>
            <FileUpload unsavedFiles={unsavedFiles} savedFiles={savedFiles} setSavedFiles={setSavedFiles} setUnsavedFiles={setUnsavedFiles} />
          </div>

          <FormTextField
            name="email"
            type="email"
            label="Email"
            placeholder=""
            errors={errors}
            ref={register({
              required: true,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            })}
            requiredMessage="Please provide a contact email."
            patternMessage="Invalid email address"
          />

          <FormActionButtons isEditing={mentor._id} deleteFn={() => deleteMentor(mentor._id)} />
        </form>
      </Grid.Column>
    </Grid>
  );
}
const style = {
  radioChoiceLabel: css({
    margin: "0 10px",
    fontWeight: "normal",
  }),
  field: css({ margin: "20px 0" }),
  fieldHeader: css({
    color: "#229292",
    textTransform: "uppercase",
    display: "block",
    marginBottom: 5,
    fontSize: "0.9rem",
  }),
};
