import React from "react";

export default function({ filterText, setFilterText, label }) {
  let inputRef;
  return (
    <input
      type="search"
      value={filterText}
      onChange={e => setFilterText(e.target.value)}
      name="filterText"
      autoComplete="no"
      autoCapitalize="no"
      placeholder={label}
      style={styles.input}
      ref={input => {
        inputRef = input;
      }}
    />
  );
}

const styles = {
  input: {
    fontSize: 18,
    height: 55,
    width: 300,
    minWidth: 150,
    padding: "8px 12px",
    border: "1px solid #aaa",
    borderRadius: 5
  }
};
