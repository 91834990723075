import React from "react";
import { css } from "glamor";
import { CurrentUserContext } from "../context/current-user-context";

import { Redirect, Link } from "react-router-dom";

export default function() {
  const [state] = React.useContext(CurrentUserContext);

  if (!state.currentUser) return null;
  //   {!!state.currentUser && <div></div>}

  return (
    <div className="dropdown">
      <button {...style.settings}><Link to="/users/edit">{state.currentUser.name}</Link></button>
      <div className="dropdown-content">
        <a href="/logout">Sign out</a>
      </div>
    </div>
  );
}
const style = {
  settings: css({
    alignItems: "right",
    color: "#969696",
    fontSize: 16,
    cursor: "pointer",
    background: "none",
    border: "none",
    textTransform: "uppercase"
  })
};
