import React, { useEffect, useState } from "react";
import { css } from "glamor";
import {Link} from 'react-router-dom'
import feathers from "../etc/feathers-client";
import { Pagination } from "semantic-ui-react";

import {Table, Thead, Tbody, Tr, Th, Td} from './Table';
import Loading from './Loading';
const PAGE_SIZE = 25;

const queryUsers = (page) => {
  return feathers.service("users").find({
    query: {
      $skip: PAGE_SIZE * (page - 1),
      $sort: { name: 1 },
      $limit: PAGE_SIZE, 
    },
  });
}

const queryProjects = (users) => {
  return feathers.service("projects").find({
    query: {
      userId: { $in: users.map(u => u._id) },
      isDeleted: null,
      $sort: { createdAt: -1 },
      $limit: 1000, //todo. add pagination
    },
  });
}

const queryResources = (users) => {
  return feathers.service("resources").find({
    query: {
      userId: { $in: users.map(u => u._id) },
      isDeleted: null,
      $sort: { createdAt: -1 },
      $limit: 1000, //todo. add pagination
    },
  });
}

export default function () {
  const [users, setUsers] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  async function requestPage(page) {
    try {
      setLoading(true);
      const usersQuery = await queryUsers(page)
      const [projectsQuery, resourcesQuery] = await Promise.all([
        queryProjects(usersQuery.data), queryResources(usersQuery.data)
      ]);

      setUsers(usersQuery.data);
      setProjects(projectsQuery.data);
      setResources(resourcesQuery.data);
      setUsersTotal(usersQuery.total)
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => { requestPage(); }, [page]);


  if (!users) return null
  if (loading) {
    return <Loading />
  }


  return (
    <div {...styles.page}>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Penn ID</Th>
            <Th>Projects</Th>
            <Th>Helpers</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          users.map(user => (
            <Tr key={user.pennId}>
              <Td>{user.name}</Td>
              <Td>{user.pennId}</Td>
              <Td>{
                projects.filter(project => project.userId === user._id)
                  .map(project => (
                    <React.Fragment key={project._id}>
                      <Link {...styles.link} to={`/project/${project._id}`}>{project.name}</Link><br />
                    </React.Fragment>
                  ))
              }</Td>
              <Td>{
                resources.filter(resource => resource.userId === user._id)
                  .map(resource => (
                    <React.Fragment key={resource._id}>
                      <Link {...styles.link} to={`/resource/${resource._id}`}>{resource.name}</Link><br />
                    </React.Fragment>
                  ))
              }</Td>
            </Tr>
          ))
        }
        </Tbody>
      </Table>
      { usersTotal > PAGE_SIZE &&
      <Pagination totalPages={Math.ceil(usersTotal / PAGE_SIZE)} defaultActivePage={1} onPageChange={(event, data) => setPage(data.activePage)} />
      }
    </div>
  );
}

const styles = {
  page: css({
    margin: "20px 0"
  }),
  link: css({
    textDecoration: 'underline'
  })
};
