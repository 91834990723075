import React from 'react'
import { css } from 'glamor'

interface IFormStepProps {
    children: React.ReactChildren
}

function FormStep(props: IFormStepProps) {
    return (
        <div className="form-step" {...styles.formStep} {...props}>
            {props.children}
        </div>
    )
}

const styles = {
    formStep: css({
        display: 'flex',
    })
}

export default FormStep