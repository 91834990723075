import React, { useEffect, useState } from "react";
import { css } from "glamor";
import feathers from "../etc/feathers-client";
import ResourceDetail from "../components/ResourceDetail";

export default function ({ match }) {
  const { resourceId } = match.params; // From URL
  const [resource, setResource] = useState(null);


  useEffect(() => {
    (async function runOnce() {
      setResource(await feathers.service("resources").get(resourceId));
    })();
  }, [resourceId]);

  if (resource && resource.isDeleted) return <h3>That helper was deleted.</h3>;
  return (
    <div style={{}}>
      <div {...style.detailContainer}>
        {!!resource && <ResourceDetail resource={resource} />}
      </div>
    </div>
  );
}

const style = {
  detailContainer: css({ margin: "40px 0" }),
  textFilter: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
};
