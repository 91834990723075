import React from "react";
import { css } from "glamor";
import Button from "./Button";
import { useToasts } from "react-toast-notifications";
export default function ShareProject({ project }) {
  const myRef = React.useRef();
  const { addToast } = useToasts();

  React.useEffect(() => {
    if (myRef && myRef.current && myRef.current.focus) {
      myRef.current.focus();
    }
  }, [myRef]);

  function handleCopy() {
    let isCopied;
    if (myRef && myRef.current && myRef.current.focus) {
      myRef.current.focus();
    }

    try {
      isCopied = !!document.execCommand("copy");
    } catch (e) {
      isCopied = false;
    }
    if (isCopied) {
      addToast("Copied!", { appearance: "success" });
    } else {
      addToast("Error copying.", { appearance: "error" });
    }
  }
  return (
    <div {...style.container}>
      <h4>Link to this project page:</h4>
      <input
        {...style.input}
        ref={myRef}
        onFocus={(event) => event.target.select()}
        value={`https://stepup.upenn.edu/login/?postLoginRedirect=project/${project._id}`}
        maxLength={60}
      />
      <Button onClick={handleCopy}>Copy</Button>
    </div>
  );
}

const style = {
  container: css({ margin: 0 }),
  input: css({
    lineHeight: "1.2rem",
    fontSize: "1em",
    padding: ".6em .8em",
    marginBottom: 30,
    border: "1px solid rgba(34,36,38,.15)",
    color: "rgba(0,0,0,.87)",
    borderRadius: 1,
    boxShadow: "0 0 0 0 transparent inset",
    transition: "color .1s ease,border-color .1s ease",
    width: "100%",
    verticalAlign: "top",
    WebkitAppearance: "none",
    WebkitTapHighlightColor: "rgba(255,255,255,0)",
    ":focus": {
      padding: ".6em",
    },
  }),
};
