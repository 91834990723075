import React from 'react'
import { css } from 'glamor'

interface IModalProps {
    requestClose: () => void // request the parent to close the modal
    children: any
    open: boolean
    childStyle: object
}

function Modal(props: IModalProps) {
    const { requestClose, children, open, childStyle } = props
    if (!open) return null
    return (
        <div {...styles.background} onClick={() => requestClose()}>
            <div {...css(styles.modal, childStyle)} onClick={e => e.stopPropagation()}>
                <div {...styles.closeIcon} onClick={() => requestClose()}>X</div>
                {children}
            </div>
        </div>
    )
}

const styles = {
    background: css({
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    modal: css({
        pointerEvents: 'visible',
        position: 'relative',
        width: '60%',
        maxWidth: '500px',
        minHeight: '300px',
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '20px',
        padding: '40px',
        border: '1px solid lightgray',
        boxShadow: '8px 8px 24px rgba(150, 150, 150, 0.1)'
    }),
    closeIcon: css({
        color: 'lightgray',
        cursor: 'pointer',
        width: '38px',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        position: 'absolute',
        top: '12px',
        right: '12px',
        ':hover': {
            boxShadow: '8px 8px 24px rgba(150, 150, 150, 0.1)',
            backgroundColor: 'rgb(240, 240, 240)',
            border: '1px solid gray',
            color: 'gray'
        }
    })
}

export default Modal
