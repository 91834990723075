import React from 'react'
import { css } from 'glamor'

interface IFilterCountProps {
    total: number,
    active: number,
    alwaysShow?: boolean,
    itemLabel?: string,
    clearFiltersFn?: () => void
}

function FilterCount({ active, total, alwaysShow, itemLabel, clearFiltersFn }: IFilterCountProps) {
    const filtered = active < total
    if (!alwaysShow && !filtered) return null
    return (
        <span>
            <b>{active}</b> of <b>{total}</b> {itemLabel ? itemLabel : 'item'} visible.
            {(filtered && clearFiltersFn) ? <a {...styles.clear} onClick={clearFiltersFn}>{` Clear filters`}</a> : null}
        </span>
    )
}

const styles = {
    clear: css({
        cursor: 'pointer'
    })
} 

export default FilterCount