import React, { useState, useEffect } from "react";
import feathers from "../etc/feathers-client";

import Modal from './Modal';
import { Grid } from "semantic-ui-react";
import Button from "./Button";
import { Redirect } from "react-router-dom";

export default function ProjectActivityModal({isOpen, project}) {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const [redirect, setRedirect] = useState(false);

  const activeProject = async () => {
    const ret = await feathers.service("projects").patch(project._id, {projectActivityStatus: "ACTIVE"});
    setIsOpen(false);

  }

  const closedProject = async () => {
    const ret = await feathers.service("projects").patch(project._id, {status: "closed", projectActivityStatus: "INACTIVE"});
    setRedirect(true);
  }

  if (redirect) {
    return <Redirect to={`/project/${project._id}`} />
  }
  return (
      <Modal open={modalIsOpen} requestClose={() => setIsOpen(false)}>
      <h1 style={{ marginTop: "1em" }}>Is your project {project.name} still active?</h1>
        <Grid centered style={{ marginTop: 20 }}>
          <Grid.Row columns={2}>
            <Grid.Column floated="left">
              <Button type="submit" handleClick={() => {activeProject()}}>Yes</Button>
            </Grid.Column>
            <Grid.Column floated="right" textAlign="right">
                <Button danger                 handleClick={e => {
                  e.preventDefault();
                  // eslint-disable-next-line
                  if (confirm("Are you sure?")) closedProject();
                }}>
                No</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal>
    );
}