import React, { useEffect, useState, useRef } from "react";
import { css } from "glamor";
import { useToasts } from "react-toast-notifications";
import CommentList from "../components/CommentList";

import TextFilter from "../components/TextFilter";
import { filterComment } from "../etc/filters";
import feathers from "../etc/feathers-client";
import CommentComposer from "../components/CommentComposer";
import ProjectDetail from "../components/ProjectDetail";

export default function ({ match }) {
  const [filterText, setFilterText] = useState("");
  const [composerText, setComposerText] = useState("");
  const { addToast } = useToasts();
  const listElement = useRef(null);

  const { projectId } = match.params; // From URL
  const [comments, setComments] = useState([]);
  const [project, setProject] = useState(null);

  const loadComments = React.useCallback(async function loadComments() {
    const commentPage = await feathers.service("comments").find({
      query: {
        projectId,
        isDeleted: null,
        $sort: { createdAt: -1 },
        $limit: 30,
      },
    });

    return setComments(commentPage.data.reverse());
    // scrollToBottom();
  }, [projectId]);

  function scrollToBottom() {
    if (listElement.current)
      listElement.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    (async function runOnce() {
      setProject(await feathers.service("projects").get(projectId));

      loadComments();

      feathers.service("comments").on("created", () => {
        console.log("comment creation event triggered"); // don't know why these aren't firing
        loadComments().then(scrollToBottom);
      });
    })()
    // scrollToBottom();
  }, [projectId, loadComments]);

  async function createComment() {
    if (!composerText) return;
    const commentData = { text: composerText, projectId };
    try {
      await feathers.service("comments").create(commentData);
      setComposerText("");

      loadComments().then(scrollToBottom);
    } catch (error) {
      addToast(error.message, { appearance: "error" });
    }
  }

  if (project && project.isDeleted) return <h3>That project was deleted.</h3>;
  return (
    <div style={{}}>
      <div {...style.detailContainer}>
        {!!project && <ProjectDetail project={project} />}
      </div>

      <div {...style.sectionTitle}>Comments</div>
      {!!comments.length && (
        <div {...style.textFilter}>
          <TextFilter
            filterText={filterText}
            setFilterText={setFilterText}
            label={"Filter comments..."}
          />
        </div>
      )}

      <CommentList
        project={project}
        comments={comments.filter((comment) =>
          filterComment({ comment, filterText })
        )}
      />
      <div style={{ float: "left", clear: "both" }} ref={listElement}></div>
      <CommentComposer
        composerText={composerText}
        setComposerText={setComposerText}
        handleSubmit={createComment}
      />
      <a id="bottom"></a>
    </div>

  );
}

const style = {
  detailContainer: css({ margin: "40px 0" }),
  textFilter: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
  sectionTitle: css({
    fontSize: 14,
    textTransform: "uppercase",
    color: "#018080",
    marginBottom: 5,
  }),
};
