import React, { useEffect, useState } from "react";
import _ from "lodash";
import UserForm from "../components/UserForm";
import { useCurrentUser } from "../context/current-user-context";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";

import feathers from "../etc/feathers-client";

export default function UserFormPage() {
  const { currentUser } = useCurrentUser();

  return (
    <div>
      <UserForm user={currentUser} />
    </div>
  );
}
