import React from "react";
import { css } from "glamor";

export default function ({
  onClick,
  handleClick = null, // Keeping for backwards compat
  children,
  size = "medium",
  type = "button",
  isTextOnly = false,
  secondary = null,
  danger = false
}) {
  return isTextOnly ? (
    <span {...style.textOnly} onClick={handleClick || onClick}>
      {children}
    </span>
  ) : (
    <button
    type={type}
    {...css(
      style.all, 
      style[size], 
      (secondary ? style.secondary : {}),
      (danger ? style.danger : {})
    )}
    onClick={handleClick || onClick}>
      {children}
    </button>
  );
}

const style = {
  textOnly: css({
    fontSize: 16,
    textDecoration: "underline",
    color: "#C9C8C8",
    cursor: "pointer",
  }),
  all: css({
    background: "#016766",
    color: "white",
    boxShadow:
      "0 2px 4px -1px rgba(0,0,0,0.20), 0 1px 10px 0 rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.14)",
    borderRadius: 3,
    "&:hover": {
      boxShadow:
        "0 5px 5px -3px rgba(0,0,0,0.20), 0 3px 14px 2px rgba(0,0,0,0.12), 0 8px 10px 1px rgba(0,0,0,0.14)",
    },
    cursor: "pointer",
  }),
  secondary: css({
    backgroundColor: 'white',
    color: "#016766",
    boxShadow: "0 1px 5px 0 rgba(0,0,0,0.20), 0 3px 1px -2px rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.14)",
    border: "none",
    outlineColor: "white"
  }),
  danger: css({
    backgroundColor: 'red',
    border: '1px solid red',
    outlineColor: 'rgba(0,0,0,0)'
  }),
  small: css({}),
  medium: css({ fontSize: "1rem", padding: "0.8125rem 1.875rem" }),
  large: css({}),
};
