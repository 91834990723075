import React from "react";
import { css } from "glamor";
import Button from "../components/Button";

export default function () {
  // const history = useHistory();
  function handleEnter() {
    window.location.href = "/login";
  }
  return (
    <main {...style.main}>
      <div {...style.heroRow}>
        <div {...css(style.column, style.heroCol1)}>
          <img alt="Stepup logo" src="/stepup-logo.svg" {...style.logo} />
          <div {...style.heroSubTitle}>Matching Penn faculty/staff projects with people who can help</div>
          <Button handleClick={handleEnter}>Login with PennKey</Button>
        </div>
        <div {...style.column}>
          <img alt="Steup home art" src="/stepup-home-art.png" {...style.art} />
        </div>
      </div>

      <div {...style.introRow}>
        <div {...style.column}>
          <div {...style.introSection}>
            <div {...style.introTitle} onClick={handleEnter}>
              I’m looking for help
            </div>
            <div>
              Create a project listing to recruit helpers. Search for available helpers with the skills and time you need and reach out to them directly.
            </div>
          </div>
        </div>
        <div {...style.column}>
          <div {...style.introSection}>
            <div {...style.introTitle} onClick={handleEnter}>
              I’m eager to help
            </div>
            <div>
              Sign up as a helper to be discovered by project leaders and be notified of new projects. Search for projects that you want to help with and reach
              out directly to project leaders.
            </div>
          </div>
        </div>
      </div>

      <div {...style.introRow}>
        <div {...style.faqColumn}>
          <div {...style.faqTitle}>FAQ</div>
          <div {...style.faqContent}>
            <div {...style.faqQuestion}>Who is this for?</div>
            <div {...style.faqAnswer}>
              Step UP is designed for faculty, staff, and students at the University of Pennsylvania. We hope Step UP provides a marketplace for project leads
              to match with interested helpers and foster interdisciplinary collaboration.
            </div>
          </div>
        </div>
        <div {...style.nonFaqColumn}></div>
      </div>
    </main>
  );
}
const style = {
  main: css({
    minHeight: "100vh",
    // padding: "0 0.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center"
  }),
  bold: css({
    color: "#000F3A",
    fontWeight: "600",
  }),
  heroRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  }),
  heroSubTitle: css({
    fontSize: "2rem",
    color: "#004C4D",
    lineHeight: "2.5rem",
    fontWeight: "600",
    marginBottom: "80px",
  }),
  introRow: css({
    margin: "20px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  }),
  column: css({ width: 450 }),
  heroCol1: css({ paddingTop: 50 }),
  faqColumn: css({ width: 550, marginTop: 24 }),
  nonFaqColumn: css({ width: 350 }),
  introSection: css({ maxWidth: 350 }),
  introTitle: css({
    fontSize: "1.65rem",
    lineHeight: "1.5rem",
    fontWeight: "700",
    color: "#004C4D",
    margin: "20px 0",
    cursor: "pointer",
  }),
  art: css({ maxWidth: 400, "@media (max-width: 479.98px)": { width: 200 } }),

  logo: css({
    marginTop: 20,
    width: 150,
    height: 90,
    // "@media (max-width: 991.98px) and (min-width: 480px)": {
    //   width: 120
    // },
    "@media (max-width: 479.98px)": { width: 80 },
  }),
  faqTitle: css({
    fontSize: "2rem",
    color: "#004C4D",
    lineHeight: "2.5rem",
    fontWeight: "800",
    marginBottom: 15,
  }),
  faqQuestion: css({
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    fontWeight: "700",
    color: "#004C4D",
    margin: "20px 0 10px 0",
  }),
  faqAnswer: css({
    fontSize: 16,
    lineHeight: "1.5rem",
    color: "#323232",
    marginBottom: "30px",
  }),
};
