import React from 'react'
import { css } from 'glamor'

interface IShowMoreProps {
    onClick: () => void
}
function ShowMore(props: IShowMoreProps) {
    return (
        <div {...styles.container}>
            <div {...styles.showMore}>
                <span  {...styles.line}/>
                <span {...styles.text} onClick={props.onClick}>Show more</span>
                <span {...styles.line}/>
            </div>
        </div>
    )
}

const styles = {
    container: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }),
    showMore: css({
        width: 240,
        display: 'flex',
    }),
    text: css({
        cursor: 'pointer',
        fontSize: '12px',
        border: '1px solid gray',
        padding: '4px 10px',
        borderRadius: 4,
        ':hover': {
            backgroundColor: 'lightgray'
        }
    }),
    line: css({
        borderTop: '1px solid gray',
        flex: 1,
        position: 'relative',
        top: 'calc(50% - 1px)'
    })
}

export default ShowMore